/**
 * Created by Mikhail Menshenin on 18.07.2024
 */
Snapshots.extractors.medalcollection = function () {
    if (aisensia.medalCollection) {
        return aisensia.medalCollection.getInfo();
    }
};

Snapshots.importers.medalCollection = function (data) {
    if (aisensia.medalCollection) {
        aisensia.medalCollection.updateInfo(data);
        aisensia.medalCollection.save();
    }
};