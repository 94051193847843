/**
 * Created by Mikhail Menshenin on 18.07.2024
 */

var MedalCollectionTool = {
    init: function () {
        if (cleverapps.config.features.includes("medalcollection")) {
            ToolModel.AUTONAVIGATE.push({
                windows: [MedalCollectionWindow],
                path: ["medalCollection"]
            });
        }
    },

    getToolItems: function () {
        var game = aisensia.medalCollection;

        var actions = {
            addMore: aisensia.medalCollection.addMore.bind(aisensia.medalCollection, 1),
            reset: function () {
                aisensia.medalCollection.reset();
                cleverapps.windows.closeAllWindows();
            }
        };

        var spawnAction = function (level) {
            return function () {
                game.addMore(1);
                game.spawn(level);
            };
        };
        var spawnMedalActions = {};
        for (var level = 1; level <= MedalCollection.MAX_LEVEL; level++) {
            var medalName = Messages.get("MedalCollection.MedalName" + level);
            spawnMedalActions["medal_" + level + "_" + medalName] = spawnAction(level);
        }

        actions["choose medal"] = spawnMedalActions;

        return actions;
    }
};

cleverapps.Tool.medalCollection = ToolFolder.create(function () {
    if (cleverapps.config.features.includes("medalcollection")) {
        return MedalCollectionTool.getToolItems();
    }
});