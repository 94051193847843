/**
 * Created by Mikhail Menshenin on 16.07.2024
 */

cleverapps.override(cleverapps.Tool.game, {
    attack: function () {
        SlotMachineActions.attack();
    },

    raid: function () {
        SlotMachineActions.raid();
    },

    "set shields": function () {
        var village = aisensia.villageManager.getVillage();
        displayToolPrompt(function (value) {
            var shields = parseInt(value || "0");
            village.setShields(shields);
        }, "Set shields (0..." + Village.MAX_SHIELDS + ")", 3);
    },

    "set energy": function () {
        var slotMachine = aisensia.slotMachine;
        var maxEnergy = slotMachine.getMaxEnergy();
        displayToolPrompt(function (value) {
            slotMachine.setEnergy(value);
        }, "Set shields (0..." + maxEnergy + ")", maxEnergy);
    },

    paylines: ToolFolder.create(function () {
        return SlotMachine.PAYLINES.reduce(function (actions, config) {
            actions[config.payline.join("_")] = function () {
                var scene = cleverapps.scenes.getRunningScene();
                var slotMachine = scene.slotMachine;

                SlotMachine.PREDEFINED_PAYLINE = config.payline;
                slotMachine.spin();
                SlotMachine.PREDEFINED_PAYLINE = undefined;
            };

            return actions;
        }, {});
    })
});

cleverapps.override(cleverapps.Tool.Debug, {
    village: ToolFolder.create(function () {
        var scene = cleverapps.scenes.getRunningScene();
        var actions = {};

        actions["go to next village"] = function () {
            aisensia.villageManager.moveNext();
        };

        if (scene instanceof VillageScene) {
            var village = scene.village;
            actions["finish one hut"] = function () {
                var hut = village.huts.find(function (hut) {
                    return !hut.isComplete();
                });
                if (hut) {
                    hut.setDamage(0);
                    hut.setLevel(hut.getMaxLevel());
                }
            };
            actions["show all huts"] = function () {
                village.huts.forEach(function (hut) {
                    if (!hut.level) {
                        hut.setLevel(1);
                    }
                });
            };

            var hutViews = scene.villageView.hutViews;
            var crossViews = scene.villageView.crossViews;
            actions = hutViews.reduce(function (actions, hutView) {
                var hutName = hutView.hut.name;

                actions["select " + hutName] = function () {
                    cleverapps.sceneDebugger.selectNode(hutView);
                };

                return actions;
            }, actions);

            actions["toggle crosses"] = function () {
                cleverapps.flags.toggle("villageCrossEnabled");
            };

            actions = crossViews.reduce(function (actions, crossView) {
                var crossId = crossView.cross.id;

                actions["select cross" + crossId] = function () {
                    cleverapps.sceneDebugger.selectNode(crossView);
                };

                return actions;
            }, actions);

            actions["toggle hut move"] = function () {
                cleverapps.flags.toggle("villageHutMove");
            };

            return actions;
        }
    })
});

cleverapps.whenAllInitialized(function () {
    if (cleverapps.config.debugMode) {
        ToolModel.AUTONAVIGATE.push({
            windows: [VillageUpgradeWindow],
            path: ["Debug", "village"]
        });
        ToolModel.AUTONAVIGATE.push({
            filter: function () {
                return cleverapps.toolModel.path === cleverapps.toolModel.root && cleverapps.scenes.getRunningScene() instanceof VillageScene;
            },
            path: ["Debug", "village"]
        });
    }
});