/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var SlotMachineView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.slotMachine = slotMachine;

        var styles = cleverapps.styles.SlotMachineView;

        var reelsBg = new cc.Sprite(bundles.slotmachine.frames.inner_part_png);
        reelsBg.setPositionRound(styles.reelsBg);
        this.addChild(reelsBg);

        var reelViews = this.slotMachine.reels.map(function (reel) {
            return new SlotMachineReelView(reel);
        }, this);
        var reelsLayout = new cleverapps.Layout(reelViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.reels.margin
        });
        reelsLayout.setPositionRound(styles.reels);
        this.addChild(reelsLayout);

        var reelsFg = new cc.Sprite(bundles.slotmachine.frames.slot_machine_png);
        reelsFg.setPositionRound(styles.reelsFg);
        this.addChild(reelsFg);
        this.setContentSize2(reelsFg.getContentSize());

        var energyProgresBar = new SlotMachineEnergyView(slotMachine);
        energyProgresBar.setPositionRound(styles.progress);
        this.addChild(energyProgresBar);

        var message = this.message = new TextWithIcon(slotMachine.message, {
            font: cleverapps.styles.FONTS.SLOTMACHINE_MESSAGE_TEXT,
            icons: {
                "@@": bundles.slotmachine.frames.coin_icon_big_png
            }
        });
        message.setPositionRound(styles.message);
        this.addChild(message);
        if (!slotMachine.message) {
            message.setScale(0);
            message.runAction(new cc.Hide());
        }

        var coinMaster = this.coinMaster = new CoinMasterView();
        coinMaster.setPositionRound(styles.coinmaster);
        this.addChild(coinMaster);

        this.slotMachine.on("message", this.createListener(this.showMessage.bind(this)));
        this.slotMachine.on("spin", this.createListener(this.hideMessage.bind(this)));
        this.slotMachine.on("coinsRecieve", this.createListener(this.animateCoinsRecieve.bind(this)));
        this.slotMachine.on("iconsRecieve", this.createListener(this.animateIconsRecieve.bind(this)));
    },

    showMessage: function (message, cb) {
        this.message.setString(message);
        this.message.runAction(
            new cc.Sequence(
                new cc.Show(),
                new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
                new cc.CallFunc(cb || function () { })
            )
        );
    },

    hideMessage: function () {
        this.message.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                new cc.Hide()
            )
        );
    },

    animateCoinsRecieve: function (animation, cb) {
        var scene = cleverapps.scenes.getRunningScene();
        var spine = new cleverapps.Spine(bundles.slotmachine.jsons.reward_gold_json);
        spine.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(spine);
        spine.setAnimation(0, animation || "animation", false);
        spine.setCompleteListenerRemove(cb || function () { });
        spine.replaceParentSamePlace(scene.movingNode);
    },

    animateIconsRecieve: function (animation, cb) {
        var styles = cleverapps.styles.SlotMachineView;
        var scene = cleverapps.scenes.getRunningScene();
        var spine = new cleverapps.Spine(bundles.slotmachine.jsons.reward_icons_json);
        cb = {
            "raid_reward": this.animateRaidPlayer.bind(this, cb),
            "shield_reward": this.animateShieldRecieve.bind(this, spine, cb)
        }[animation] || cb || function () { };
        spine.setPositionRound(styles.icon_animation);
        this.addChild(spine);
        spine.setAnimation(0, animation || "animation", false);
        if (animation === "shield_reward") {
            spine.setCompleteListener(cb);
        } else {
            spine.setCompleteListenerRemove(cb);
        }
        spine.replaceParentSamePlace(scene.movingNode);
    },

    animateShieldRecieve: function (flyIcon, cb) {
        flyIcon.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(flyIcon, "shields", {
                duration: 0.3,
                scale: 0.5
            }),
            new cc.CallFunc(cb || function () { }),
            new cc.FadeOut(0.1),
            new cc.RemoveSelf()
        ));
    },

    animateRaidPlayer: function (cb) {
        var styles = cleverapps.styles.VillageRaidScene;
        var scene = cleverapps.scenes.getRunningScene();
        var village = aisensia.villageManager.getRaidVillage();
        var controlPanel = new RaidControlPanelView(village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        scene.addChild(controlPanel);
        var target = controlPanel.avatar;
        var targetRect = target.getSceneBoundingBox();
        var targetPosition = cc.rectGetCenter(targetRect);
        var targetNode = new cc.Node();
        targetNode.setPosition(targetPosition.x - targetRect.width / 2, targetPosition.y - targetRect.height / 2);
        targetNode.setContentSize(targetRect.width, targetRect.height);
        scene.addChild(targetNode);
        this.coinMaster.animateRaidPlayer(targetNode);
        controlPanel.animateRaidPlayer(cb);
        cleverapps.windows.trigger("hideShadow");
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOTMACHINE_MESSAGE_TEXT: {
        name: "default",
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
});

cleverapps.styles.SlotMachineView = {
    reelsBg: {
        x: { align: "center" },
        y: { align: "center", dy: -25 }
    },

    reels: {
        margin: 25,

        x: { align: "center", dx: -4 },
        y: { align: "center", dy: -24 }
    },

    reelsFg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    progress: {
        x: { align: "center" },
        y: { align: "bottom", dy: 170 }
    },

    message: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: 260 }
    },

    coinmaster: {
        x: { align: "center" },
        y: { align: "top", dy: -26 }
    },

    icon_animation: {
        x: { align: "center", dx: 2 },
        y: { align: "center", dy: 20 }
    },

    shield: {
        scale: 0.58,
        x: { align: "center", dx: -1 },
        y: { align: "center", dy: 10 }
    }
};

cleverapps.styles.SlotMachineReelView = {
    width: 140,
    height: 388
};