/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var SlotMachineButtonView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.slotMachine = slotMachine;

        this.background = new cleverapps.Spine(bundles.slotmachine.jsons.button_json);
        this.setContentSize(this.background.getContentSize());
        this.background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.background);
        this.background.setAnimation(0, "idle", true);
        this.idle = "idle";
        this.animated = false;

        cleverapps.UI.onClick(this, this.onClicked.bind(this), {
            interactiveScale: false
        });
        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        this.slotMachine.on("spin", this.animatePressButton.bind(this));
    },

    animatePressButton: function () {
        this.background.setAnimation(0, "click", false);
        this.animated = true;
        this.background.setCompleteListener(function () {
            this.animated = false;
            this.animateIdle();
        }.bind(this));
    },

    animateIdle: function () {
        if (!this.animated) {
            this.background.setAnimation(0, this.idle, true);
        }
    },

    onClicked: function () {
        var isForce = cleverapps.forces.isRunningForce();

        if (this.slotMachine.spin(isForce)) {
            if (isForce) {
                cleverapps.force.closeRunningForce();
            }
        }
    },

    onMouseOver: function () {
        this.idle = "hover";
        this.animateIdle();
    },

    onMouseOut: function () {
        this.idle = "idle";
        this.animateIdle();
    }
});
