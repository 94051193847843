/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

cleverapps.Plot.onFirstInit = function (focus, returnClassOnly) {
    if (returnClassOnly) {
        return GameScene;
    }

    cleverapps.meta.wantsToPlay(focus);
};