/**
 * Created by Mikhail Menshenin on 10.07.2024
 */

var VillageUpgradeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (village) {
        var styles = cleverapps.styles.VillageUpgradeWindow;

        this._super({
            title: "VillageUpgradeWindow.Title",
            name: "VillageUpgradeWindow",
            content: this.createContent(village, styles),
            styles: styles
        });
    },

    createContent: function (village, styles) {
        var rows = village.huts.map(function (hut) {
            return this.createRow(hut, styles.row);
        }.bind(this));

        var layout = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.table.margin,
            padding: styles.table.padding
        });

        return layout;
    },

    createRow: function (hut, styles) {
        var bg = cleverapps.UI.createScale9Sprite(bundles.windows.frames.windows_group_bg_png);
        bg.setContentSize(styles);

        var icon = this.createIconWithProgress(hut, styles);

        var price = hut.getPrice();
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            width: styles.button.width,
            height: styles.button.height,
            text: TextWithIcon.ICONS_BY_NAME[price.type] + price.amount,
            onClicked: hut.upgrade.bind(hut)
        });

        var completed = cleverapps.UI.generateOnlyText("completed", cleverapps.styles.FONTS.WHITE_TEXT);

        var layout = new cleverapps.Layout([icon, hut.isComplete() ? completed : button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        layout.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        bg.addChild(layout);

        hut.once("changeLevel", this.createListener(this.close.bind(this)));

        return bg;
    },

    createIconWithProgress: function (hut, styles) {
        var frame = bundles[hut.village.getBundleId()].frames[hut.getFrameId()];
        var icon = new cc.Sprite(frame);

        if (hut.isComplete()) {
            cleverapps.UI.fitToBox(icon, styles.icon);
            return icon;
        }

        var nextFrame = bundles[hut.village.getBundleId()].frames[hut.getFrameId(hut.level + 1)];
        var nextIcon = new cc.Sprite(nextFrame);
        cleverapps.UI.fitToBox(nextIcon, styles.next_icon);
        var iconBlockItems = [nextIcon];

        if (hut.level) {
            var arrow = new cc.Sprite(bundles.villageupgrade_window.frames.arrow_png);
            iconBlockItems.unshift(arrow);
            cleverapps.UI.fitToBox(icon, styles.small_icon);
            iconBlockItems.unshift(icon);
        }

        var iconBlock = new cleverapps.Layout(iconBlockItems, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.icon_block.margin,
            padding: styles.icon_block.padding
        });

        var progress = this.createProgressBar(hut, styles);

        var layout = new cleverapps.Layout([iconBlock, progress], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.icon_with_progress.margin,
            padding: styles.icon_with_progress.padding
        });

        return layout;
    },

    createProgressBar: function (hut, styles) {
        var bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_orange_small,
            background: bundles.progress_bar.frames.bg_dark_small
        });
        bar.setLength(styles.bar.width);
        bar.setGoal(hut.upgrades.length - 1);
        bar.setPercentage(hut.level ? hut.level - 1 : 0);

        var starFrame = bundles.villageupgrade_window.frames.star_png;
        var starShadowFrame = bundles.villageupgrade_window.frames.star_shadow_png;
        for (var i = 0; i < 4; i++) {
            var star = new cc.Sprite(i < hut.level ? starFrame : starShadowFrame);
            star.setAnchorPoint(0.5, 0.5);
            star.setPosition(bar.width * i / 3, bar.height / 2);
            bar.addChild(star);
        }

        return bar;
    },

    listBundles: function () {
        return ["villageupgrade_window"];
    }
});

cleverapps.styles.VillageUpgradeWindow = {
    table: {
        margin: 12,
        padding: 0
    },

    row: {
        width: 800,
        height: 220,
        margin: 100,
        padding: 20,

        icon: {
            width: 180,
            height: 180
        },

        small_icon: {
            width: 120,
            height: 120
        },

        next_icon: {
            width: 160,
            height: 150
        },

        icon_block: {
            margin: 40,
            padding: 0
        },

        icon_with_progress: {
            margin: 30,
            padding: 0
        },

        bar: {
            width: 420
        },

        button: {
            width: 200,
            height: 80
        }
    }
};