/**
 * Created by Mikhail Menshenin on 21.08.2024
 */

var CoinMasterView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.CoinMasterView;
        this.setContentSize2(styles.size);

        var name = this.name = cleverapps.UI.generateOnlyText("Jonny", cleverapps.styles.FONTS.COINMASTER_NAME_TEXT);
        name.setAnchorPoint(0, 0.5);
        name.setPositionRound(styles.name);
        this.addChild(name);

        var gold = this.gold = cleverapps.UI.generateOnlyText("876,000", cleverapps.styles.FONTS.COINMASTER_GOLD_TEXT);
        gold.setAnchorPoint(0, 0.5);
        gold.setPositionRound(styles.gold);
        this.addChild(gold);

        this.updateRaidPlayer();
    },

    updateRaidPlayer: function () {
        var styles = cleverapps.styles.CoinMasterView;
        var village = aisensia.villageManager.getRaidVillage();
        var player = village.player;
        var name = player.name;
        var gold = village.gold;

        if (this.avatar) {
            this.avatar.removeFromParent();
        }

        var avatar = this.avatar = new cleverapps.UI.Avatar(player, undefined, styles.avatar.Avatar);
        avatar.setPositionRound(styles.avatar);
        cleverapps.UI.fitToBox(avatar, styles.avatar);
        this.addChild(avatar);

        this.name.setString(cleverapps.UI.cutPlayerName(name, 7));
        this.gold.setString(aisensia.getNumberWithCommas(gold));
    },

    animateRaidPlayer: function (target) {
        var flyIcon = this.avatar;
        flyIcon.runAction(new cc.Spawn(
            AnimationsLibrary.animateCollect(flyIcon, target, {
                duration: 0.5
            }),
            new cc.ScaleTo(0.5, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 1.6 : 2.2)
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COINMASTER_NAME_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    },
    COINMASTER_GOLD_TEXT: {
        name: "nostroke",
        size: 30,
        color: new cc.Color(253, 214, 47, 255)
    }
});

cleverapps.styles.CoinMasterView = {
    size: {
        width: 241,
        height: 104
    },

    avatar: {
        width: 105,
        height: 105 ,
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 2 },
        Avatar: {
            x: 0,
            top: 0,
            bottom: 0,
            frame: {
                scale: 1,
                dx: 0,
                dy: 0
            }
        },
    },

    name: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: 25 }
    },

    gold: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: -20 }
    }
};