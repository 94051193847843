/**
 * Created by Mikhail Menshenin on 05.07.2024
 */

SaveFinalResultsAction = cleverapps.extendFunc(SaveFinalResultsAction, function (f) {
    this._super(function () {
        var game = this.game;
        var outcome = game.outcome;

        if (outcome === GameBase.OUTCOME_VICTORY) {
            if (game.score.points >= 250) {
                cleverapps.eventBus.trigger("taskEvent", DailyTasks.GET_HIGH_SCORE);
            }

            if (game.score.points >= 150) {
                cleverapps.eventBus.trigger("taskEvent", DailyTasks.GET_SCORE);
            }
        }

        f();
    }.bind(this));
});