/**
 * Created by Mikhail Menshenin on 10.07.2024
 */

var VillageUpgradeToolbarItem = function (village) {
    ToolbarItem.call(this, ToolbarItem.VILLAGE_UPGRADE);
    this.village = village;

    this.enable();
};

VillageUpgradeToolbarItem.prototype = Object.create(ToolbarItem.prototype);
VillageUpgradeToolbarItem.prototype.constructor = ToolbarItem;

VillageUpgradeToolbarItem.prototype.onClick = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var village = scene.village;

    village.openUpgradeWindow();
};

VillageUpgradeToolbarItem.prototype.isVisible = function () {
    return true;
};
