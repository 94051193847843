/**
 * Created by Mikhail Menshenin on 24.04.2024
 */

var LevelMasteryView = cc.Node.extend({
    ctor: function (options) {
        options = options || {};
        var styles = cleverapps.styles.LevelMasteryView;
        var type = this.type = options.type || "window";
        styles = this.styles = Object.assign({}, styles.Type[type], styles);
        var maxLevel = aisensia.levelMastery.getMaxLevel();
        var reward = aisensia.levelMastery.getCurrentReward();
        this.level = aisensia.levelMastery.level;

        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var progressBar = this.progressBar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.green_large,
            barText: {
                dy: 2,
                font: cleverapps.styles.FONTS.STARS_PROGRESS_BAR_TEXT
            }
        });
        progressBar.setLength(styles.width);
        this.setContentSize(this.progressBar.getContentSize());
        progressBar.setGoal(maxLevel);
        progressBar.setPercentage(type === "victory" ? this.level - 1 : this.level);
        progressBar.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(progressBar);

        var cupIcon = this.cupIcon = this.createCupIcon(styles.cup);
        cupIcon.setPositionRound(styles.cup);
        this.addChild(cupIcon);

        var rewardIcon = this.rewardIcon = new LevelMasteryRewardView(reward);
        rewardIcon.setPositionRound(styles.reward);
        this.addChild(rewardIcon);

        if (type === "victory") {
            var labelBg = this.labelBg = cleverapps.UI.createScale9Sprite(bundles.progress_bar.frames.progressbar_bottom_label_png);
            labelBg.setPositionRound(styles.label);
            labelBg.setContentSize(styles.label.width, labelBg.height);
            progressBar.addChild(labelBg);

            var label = cleverapps.UI.generateOnlyText("LevelMastery.Label", cleverapps.styles.FONTS.LEVELMASTERY_LABEL_TEXT);
            label.setPositionRound(styles.label.text);
            label.fitTo(labelBg.width - 2 * styles.label.text.padding, label.height);
            labelBg.addChild(label);

            cleverapps.UI.wrapWithPadding(this, styles.wrapPadding, true);
        }

        if (type === "scene") {
            var timer = this.createTimer(styles.timer);
            this.addChild(timer);
        }

        this.setContentSize(cleverapps.UI.calculateContentSize(this));

        cleverapps.aims.registerTarget("levelMastery", this, {
            controls: "levelmastery_progress",
            withoutDelta: true,
            collectAnimate: this.collectAnimate.bind(this)
        });

        aisensia.levelMastery.on("updateStage", this.updateStage.bind(this), this);
        aisensia.levelMastery.on("updateLevel", this.updateLevel.bind(this), this);
    },

    updateSize: function () {
        if (this.type === "scene") {
            var width = cleverapps.resolution.sceneRect.width - this.styles.sceneWidthPadding * 2;
            var length = Math.min(width, this.styles.width);

            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                length = Math.round(length * LevelMasteryView.VERTICAL_MODE_PROGRESSBAR_LENGTH_COEFF);
            }

            this.setScale(1);
            this.setPositionRound(this.styles);
            this.progressBar.setLength(length);
            this.setContentSize(this.progressBar.getContentSize());
            this.setContentSize(cleverapps.UI.calculateContentSize(this));

            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                var scene = cleverapps.scenes.getRunningScene();

                cleverapps.UI.inflateToBoundaries(this, [scene.upMenuContainer], {
                    padding: this.styles.padding
                });
            }
        }
    },

    beforeWinClose: function () {
        if (this.animation) {
            this.animation.setVisible(false);
        }

        this.runAction(new cc.FadeOut(0.1));
    },

    collectAnimate: function (animation) {
        var styles = cleverapps.styles.LevelMasteryView;
        this.setCascadeOpacityEnabledRecursively(true);

        this.animation = animation;

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            AnimationsLibrary.animateDelta("+" + 1 + " **", {
                icon: this.animation,
                icons: {
                    "**": this.animation
                },
                target: this,
                x: styles.rewardAnimation.text.x,
                y: styles.rewardAnimation.text.y,
                font: cleverapps.styles.FONTS.REWARD_TEXT
            })
        ));

        this.runAction(
            cc.sequence(
                cc.delayTime(1.8),
                cc.callFunc(function () {
                    this.progressBar.setPercentage(this.level, {
                        animated: true
                    });
                }.bind(this))
            )
        );
    },

    rewardAnimation: function (f) {
        var scaleOriginal = this.getScale();

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(
            cc.sequence(
                cc.delayTime(0.5),
                cc.callFunc(function () {
                    this.rewardIcon.ribbonBack.runAction(
                        cc.moveBy(0.1, 0, -15)
                    );

                    this.rewardIcon.ribbon.runAction(
                        cc.moveBy(0.1, 0, -15)
                    );

                    this.rewardIcon.icon.runAction(
                        cc.moveBy(0.1, 0, 15)
                    );

                    cleverapps.audio.playSound(bundles.main.urls.youreward_1);
                }.bind(this)),
                cc.delayTime(0.1),
                cc.callFunc(function () {
                    var scene = cleverapps.scenes.getRunningScene();
                    var movingNode = cleverapps.scenes.getMovingNode(this);
                    var flyIcon = this.rewardIcon.icon;
                    var target = cc.p(scene.width / 2, scene.height / 2);
                    flyIcon.replaceParentSamePlace(movingNode, { keepScale: true });
                    var jumpOffset = cc.pSub(target, flyIcon.getPosition());
                    flyIcon.runAction(
                        cc.sequence(
                            cc.delayTime(0.1),
                            new cc.JumpAnimation(0.6, flyIcon, jumpOffset, 600),
                            cc.callFunc(function () {
                                var shine = AnimationsLibrary.rays(flyIcon);
                                var stageReward = aisensia.levelMastery.getCurrentReward();
                                var reward = {};
                                reward[stageReward.type] = stageReward.value;
                                var rewardWindow = new RewardWindow(reward, {
                                    event: cleverapps.EVENTS.EARN.LEVELMASTERY,
                                    shine: false
                                });

                                var replaceRewardIcon = function () {
                                    var windowReward = rewardWindow.rewardsList.targets[0];
                                    rewardWindow.styles.shadow = false;
                                    windowReward.icon.removeFromParent(true);
                                    windowReward.shine.removeFromParent();
                                    var parentOrigin = windowReward.text.parent;
                                    windowReward.text.replaceParent(flyIcon);
                                    windowReward.text.setPosition(flyIcon.width / 2, -40);
                                    windowReward.text.replaceParentSamePlace(parentOrigin);
                                    windowReward.icon = flyIcon;
                                    cleverapps.timeouts.setTimeout(function () {
                                        windowReward.shine = shine;
                                    }, 1000);
                                };

                                if (rewardWindow.initializedSuccess) {
                                    replaceRewardIcon();
                                } else {
                                    var onShow = rewardWindow.onShow;
                                    rewardWindow.onShow = function () {
                                        replaceRewardIcon();
                                        onShow.apply(rewardWindow, arguments);
                                    };
                                }

                                cleverapps.meta.onceNoWindowsListener = function () {
                                    cleverapps.styles.Window.shadow = true;
                                    f();
                                };
                            })
                        )
                    );
                }.bind(this)),
                cc.delayTime(0.1),
                cc.spawn(
                    cc.fadeOut(0.3),
                    cc.scaleTo(0.3, 0.6 * scaleOriginal)
                ),
                cc.callFunc(function () {
                    this.setScale(scaleOriginal);
                }.bind(this))
            )
        );
    },

    createCupIcon: function () {
        var cupIcon = new cc.Sprite(bundles.levelmastery.frames.cup_png);
        cupIcon.setAnchorPoint(0.5, 0.5);
        return cupIcon;
    },

    onClick: function () {
        cleverapps.meta.display({
            focus: "LevelMasteryProgressBarClicked",
            action: function (f) {
                var mission = aisensia.levelMastery.mission;

                if (!mission.isRunning()) {
                    mission.displayCompleted(f);
                    return;
                }

                new LevelMasteryWindow(mission);
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    },

    registerControl: function () {
        var progressControl = new HidingNode(this, HidingNode.DIR.NONE);
        cleverapps.meta.registerControl("levelmastery_progress", progressControl);

        cleverapps.UI.onPressed(progressControl, this.createListener(this.onClick.bind(this)));

        return progressControl;
    },

    createTimer: function (styles) {
        var timer = this.timer = new cleverapps.CountDown(aisensia.levelMastery.getTimeLeft(), {
            onFinish: function () {
                this.stopTimer();
            }.bind(this)
        });

        var timerLabel = cleverapps.UI.createScale9Sprite(styles.background.frame);
        timerLabel.setContentSize(styles.background.width, timerLabel.height);
        timerLabel.setPositionRound(styles);

        var timerIcon = new cc.Sprite(styles.icon.frame);
        timerIcon.setPositionRound(styles.icon);
        timerLabel.addChild(timerIcon);

        var textTimer = this.timerView = new cleverapps.CountDownView(timer, { font: styles.font });
        textTimer.setPositionRound(styles.text);
        timerLabel.addChild(textTimer);
        timerLabel.setContentSize(Math.max(styles.background.width, textTimer.text.width + 140), timerLabel.height);

        return timerLabel;
    },

    stopTimer: function () {
        this.hideTimer();
    },

    hideTimer: function () {
        if (this.timer) {
            this.timer.remove();
            delete this.timer;
        }

        if (this.timerView) {
            this.timerView.removeFromParent();
            delete this.timerView;
        }
    },

    updateStage: function (silent) {
        var styles = cleverapps.styles.LevelMasteryView;
        var maxLevel = aisensia.levelMastery.getMaxLevel();
        var reward = aisensia.levelMastery.getCurrentReward();
        var level = aisensia.levelMastery.level;

        this.progressBar.setGoal(maxLevel);
        this.progressBar.setPercentage(level);

        this.rewardIcon.removeFromParent();

        var rewardIcon = this.rewardIcon = new LevelMasteryRewardView(reward);
        rewardIcon.setPositionRound(styles.reward);
        this.addChild(rewardIcon);

        if (!silent) {
            rewardIcon.setVisible(false);
        }
    },

    updateLevel: function (cb) {
        this.level = aisensia.levelMastery.level;
        this.progressBar.setPercentage(this.level, {
            animated: true,
            callback: cb
        });
    },

    nextRewardAnimation: function (cb) {
        if (!aisensia.levelMastery.isAvailable) {
            cleverapps.windows.trigger("hideShadow");

            if (cb) {
                cb();
            }

            return;
        }
        cleverapps.windows.trigger("showShadow");

        this.setCascadeOpacityEnabledRecursively(true);
        this.setOpacity(0);

        this.runAction(
            cc.sequence(
                cc.delayTime(0.5),
                cc.fadeIn(0.3),
                cc.callFunc(function () {
                    var reward = aisensia.levelMastery.getCurrentReward();
                    var flyIcon = reward.getIcon();
                    var scene = cleverapps.scenes.getRunningScene();
                    var movingNode = cleverapps.scenes.getMovingNode(this);
                    flyIcon.setScale(this.getScale());
                    flyIcon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
                    scene.addChild(flyIcon);
                    flyIcon.replaceParentSamePlace(movingNode, { keepScale: true });
                    var jumpPosition = cc.pSub(flyIcon.getRelativeCoordinates(this.rewardIcon.icon), flyIcon);
                    AnimationsLibrary.rays(flyIcon);

                    flyIcon.setScale(0.3);

                    cleverapps.audio.playSound(bundles.main.urls.youreward_2);

                    flyIcon.runAction(
                        cc.sequence(
                            cleverapps.UI.animateScale({
                                duration: 0.3,
                                scale: 1
                            }),
                            cc.delayTime(2),
                            cc.callFunc(function () {
                                cleverapps.audio.playSound(bundles.main.urls.youreward_3);
                            }),
                            cc.spawn(
                                new cc.JumpAnimation(0.6, flyIcon, jumpPosition, 600),
                                cc.scaleTo(0.6, flyIcon.getScale() / flyIcon.adjustScaleTo(this.rewardIcon.icon).y),
                                cc.sequence(
                                    cc.delayTime(0.5),
                                    cc.fadeOut(0.1)
                                )
                            ),
                            cc.callFunc(function () {
                                this.nextRewardIconAnimation();
                            }.bind(this)),
                            cc.delayTime(0.2),
                            cc.removeSelf(),
                            cc.callFunc(function () {
                                AnimationsLibrary.rays(this.rewardIcon);
                                cleverapps.windows.trigger("hideShadow");

                                if (cb) {
                                    cb();
                                }
                            }.bind(this))

                        )
                    );
                }.bind(this))
            )
        );
    },

    nextRewardIconAnimation: function () {
        this.rewardIcon.setVisible(true);
        this.rewardIcon.setCascadeOpacityEnabledRecursively(true);
        this.rewardIcon.setOpacity(0);
        this.rewardIcon.setScale(0.6);

        this.rewardIcon.runAction(
            cc.sequence(
                cc.spawn(
                    cc.scaleTo(0.5, 1),
                    cc.fadeIn(0.3)
                ),
                cc.callFunc(function () {
                    var whiteStarAnimation = new cleverapps.Spine(bundles.levelmastery.jsons.white_star_json);
                    whiteStarAnimation.setAnimation(0, "animation", false);
                    whiteStarAnimation.setCompleteListenerRemove();
                    whiteStarAnimation.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
                    this.rewardIcon.addChild(whiteStarAnimation);
                }.bind(this))
            )
        );
    }
});

SimpleMainScene.prototype.onSceneLoaded = cleverapps.extendFunc(SimpleMainScene.prototype.onSceneLoaded, function () {
    this._super();

    if (aisensia.levelMastery.isAvailable) {
        var levelMastery = this.levelMastery = new LevelMasteryView({ type: "scene" });
        levelMastery.setPositionRound(cleverapps.styles.LevelMasteryView);
        this.addChild(levelMastery);
        levelMastery.setLocalZOrder(15);
        levelMastery.registerControl();
        levelMastery.updateSize();
    }
});

VictoryWindow.prototype.listComponents = cleverapps.extendFunc(VictoryWindow.prototype.listComponents, function () {
    var components = this._super();

    if (aisensia.levelMastery.isAvailable && aisensia.levelMastery.level) {
        var levelMastery = new LevelMasteryView({ type: "victory" });
        components.push(levelMastery);
    }

    return components;
});

LevelMasteryView.VERTICAL_MODE_PROGRESSBAR_LENGTH_COEFF = 1.5;

Reward.prototype.customCollectAnimation = cleverapps.extendFunc(Reward.prototype.customCollectAnimation, function () {
    if (this.type === GameBase.REWARD_LEVEL_MASTERY) {
        var targetType = this.getFlyingTarget();
        var target = cleverapps.aims.getTarget(targetType);
        var animation = this.getIcon();
        return new cc.CallFunc(function () {
            target.aim.collectAnimate(animation);
        });
    }

    return this._super(target);
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_LABEL_TEXT: {
        size: 40,
        color: new cc.Color(137, 64, 45, 255)
    }
});

cleverapps.styles.LevelMasteryView = {
    x: { align: "center" },
    y: { align: "top", dy: -115 },

    sceneWidthPadding: 120,

    padding: {
        x: 100
    },
    maxScale: 1,

    Type: {
        scene: {
            width: 860,
            cup: {
                x: { align: "left", dx: -42 },
                y: { align: "center", dy: 2 }
            },
            timer: {
                x: { align: "center" },
                y: { align: "bottom", dy: -44 },
                font: cleverapps.styles.FONTS.SIDEBAR_ICON_TEXT,
                text: {
                    x: { align: "center", dx: 34 },
                    y: { align: "center", dy: 10 },
                    width: 200
                },
                icon: {
                    frame: bundles.levelmastery.frames.clock_png,
                    x: { align: "left", dx: -1 },
                    y: { align: "center", dy: 11 }
                },
                padding: 0,
                background: {
                    frame: bundles.progress_bar.frames.progressbar_bottom_label_png,
                    width: 214
                }
            }
        },
        victory: {
            width: 800,
            wrapPadding: {
                left: 30
            },

            cup: {
                x: { align: "left", dx: -65 },
                y: { align: "center", dy: 2 }
            },

            label: {
                x: { align: "center" },
                y: { align: "bottom", dy: -59 },

                width: 400,

                text: {
                    x: { align: "center" },
                    y: { align: "center", dy: 6 },

                    padding: 20
                }
            }
        },
        window: {
            cup: {
                x: { align: "left", dx: -42 },
                y: { align: "center", dy: 2 }
            },

            width: 700
        }
    },

    reward: {
        x: { align: "right", dx: 46 },
        y: { align: "center" }
    },

    rewardAnimation: {
        x: { align: "center" },
        y: { align: "center", dy: 150 },

        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 110 }
        }
    },

    animation: {
        x: { align: "left", dx: -60 },
        y: { align: "center" }
    }
};