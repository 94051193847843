/**
 * Created by Mikhail Menshenin on 28.08.2024
 */

var VillageActionScene = VillageScene.extend({
    onSceneLoaded: function (sceneName) {
        this._super(sceneName);

        cleverapps.meta.displayWhenFreeFocus({
            focus: sceneName + "Scene",
            control: [],
            actions: [
                function (f) {
                    this.background.runAction(
                        new cc.Sequence(
                            new cc.ScaleTo(0.4, 1.2 * this.background.scale).easing(cc.easeOut(1)),
                            new cc.CallFunc(f)
                        )
                    );
                }.bind(this),
                function (f) {
                    cleverapps.meta.setEventNodes([this.villageView]);
                    this.handleFinishEvent(f);
                }.bind(this),
                function (f) {
                    cleverapps.meta.wantsToPlay(f);
                }
            ]
        });
    },

    handleFinishEvent: function (f) {
        throw new Error("Implement in successor");
    }
});