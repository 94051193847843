/**
 * Created by Mikhail Menshenin on 27.08.2024
 */

var DualAttention = Attention.extend({
    ctor: function (options) {
        this._super();

        options = this.options = options || {};
        var model = this.model = options.model;
        var key = options.key || "count";
        var changeEvent = options.event || "change" + key;

        this.updateIcon();
        if (model) {
            model.on(changeEvent, this.updateIcon.bind(this));
        }
    },

    updateIcon: function () {
        var options = this.options;
        var key = options.key || "count";
        var value = this.model[key] || options.value || false;

        if (value) {
            this.setVisible(true);
            this.setSpriteFrame(bundles.attention.frames.attention_png);
        } else {
            this.setVisible(false);
        }
    }
});
