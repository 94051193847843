/**
 * Created by Mikhail Menshenin on 24.07.2024
 */

var TutorialScenario = function (scenario) {
    this.name = scenario.name;
    this.context = scenario.context;
    this.steps = scenario.steps || [];

    this.onStartScenario();
};

TutorialScenario.prototype.step = function (step) {
    var data = cleverapps.clone(this.steps[step] || {});

    data.force = data.force || {};

    data.force.finger = false;
    if (data.force.pointer !== false) {
        data.force.pointer = true;
    }

    if (this.context.prepareData) {
        data = this.context.prepareData(data);
    }

    return data;
};

TutorialScenario.prototype.isLastStep = function (step) {
    return step === this.steps.length - 1;
};

TutorialScenario.prototype.onStartStep = function (step) {
    var data = this.step(step);

    if (this.context.onStartStep) {
        this.context.onStartStep(data);
    }
};

TutorialScenario.prototype.onFinishStep = function (step) {
    var data = this.step(step);

    if (this.context.onFinishStep) {
        this.context.onFinishStep(data);
    }
};

TutorialScenario.prototype.onStartScenario = function () {
    if (this.context.onStartScenario) {
        this.context.onStartScenario();
    }
};

TutorialScenario.prototype.onFinishScenario = function () {
    if (this.context.onFinishScenario) {
        this.context.onFinishScenario();
    }
};

TutorialScenario.prototype.getForceElement = function (step) {
    var data = this.step(step);

    if (this.context.getForceElement) {
        return this.context.getForceElement(data);
    }

    return ForceView.NO_ELEMENT;
};

TutorialScenario.prototype.getForceOptions = function (step) {
    var options = {};
    var data = this.step(step);

    if (this.context.getForceOptions) {
        options = this.context.getForceOptions(data);
    }

    options.highlights = this.getHighlightElements(step);
    options.importantNodes = this.getImportantNodes(step);

    return options;
};

TutorialScenario.prototype.getHighlightElements = function (step) {
    var data = this.step(step);

    if (this.context.getHighlightElements) {
        return this.context.getHighlightElements(data);
    }

    return [];
};

TutorialScenario.prototype.getImportantNodes = function (step) {
    var data = this.step(step);

    if (this.context.getImportantNodes) {
        return this.context.getImportantNodes(data);
    }

    return [];
};