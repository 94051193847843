/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

SideBarSlotView.prototype.insert = function (icon) {
    this.iconView = icon;
    this.addChild(icon);

    var styles = cleverapps.styles.SideBarSlot;

    icon.setPositionRound(this.iconView.model.countOfSlots() > 1 ? styles.iconsGroup : styles.icon);

    cleverapps.meta.registerControl(icon.model.control || this.getControlName(), this, icon.model.hiddenByDefault || Object.values(cleverapps.Environment).filter(function (scene) {
        return ![cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_SLOT_MACHINE].includes(scene);
    }));
};

SideBarSlotView.prototype.getStyles = cleverapps.extendFunc(SideBarSlotView.prototype.getStyles, function () {
    if (cleverapps.environment.hasScene(cleverapps.Environment.SCENE_SLOT_MACHINE)) {
        return cleverapps.styles.SideBarSlot[cleverapps.Environment.SCENE_SLOT_MACHINE];
    }
    return cleverapps.styles.SideBarSlot;

});

cleverapps.styles.SideBarSlot[cleverapps.Environment.SCENE_SLOT_MACHINE] = cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 140,
            horizontal: 20
        },
        {
            top: 140,
            horizontal: 40
        },
        {
            top: 176,
            horizontal: 70
        }
    ]
}, true);