/**
 * Created by Slava on 02.07.2024
 */

DailyTasksCompleteAllView.prototype.createTextLayout = cleverapps.extendFunc(DailyTasksCompleteAllView.prototype.createTextLayout, function () {
    var styles = cleverapps.styles.DailyTasksCompleteAllView;

    var items = [];

    var text = this.createText();
    items.push(text);

    if (this.timer) {
        items.push(this.timer);
    }

    this.prize = new PrizeBarComponent({
        currentValue: this.task.progress,
        goalValue: this.task.goal,
        collected: this.task.collected,
        reward: this.task.getReward(),
        progressBar: {
            font: cleverapps.styles.FONTS.DAILY_TASK_PROGRESS_FONT_LARGE,
            progressBgImage: bundles.dailytasks.frames.daily_task_bar_blue_bg,
            width: styles.textLayout.width
        },
        onCollect: this.task.givePrize.bind(this.task)
    });

    items.push(this.prize);
    return new cleverapps.Layout(items, {
        direction: cleverapps.UI.VERTICAL,
        margin: styles.textLayout.margin,
        padding: styles.textLayout.padding
    });
});