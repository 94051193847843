/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

Simple.prototype.isForceAvailable = function () {
    return false;
};

Simple.prototype.getRequiredStars = function () {
    return 20;
};