/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var CoinKingdomScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        var styles = cleverapps.styles.CoinKingdomScene;

        this._super(cleverapps.Environment.SCENE_SLOT_MACHINE);

        var slotMachine = this.slotMachine = aisensia.slotMachine;
        var cloudsBundle = bundles[this.getBackgroundStyles().bundle];

        var clouds1 = this.clouds1 = new cc.Sprite(cloudsBundle.frames.clouds3_png);
        clouds1.setAnchorPoint(0.5, 1);
        this.addChild(clouds1);
        clouds1.setVisible(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL);

        var slotMachineView = this.slotMachineView = new SlotMachineView(slotMachine)
        slotMachineView.setAnchorPoint(0.5, 0);
        this.addChild(slotMachineView);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            slotMachineView.setScale(0.87);
        }

        var clouds2 = this.clouds2 = new cc.Sprite(cloudsBundle.frames.clouds2_png);
        clouds2.setAnchorPoint(0.5, 1);
        this.addChild(clouds2);

        var button = this.button = new SlotMachineButtonView(slotMachine);
        button.setAnchorPoint(0.5, 0);
        this.addChild(button);

        var clouds3 = this.clouds3 = new cc.Sprite(cloudsBundle.frames.clouds3_png);
        clouds3.setAnchorPoint(0.5, 1);
        this.addChild(clouds3);

        this.addSlotMachineToolbar();

        var village = aisensia.villageManager.getVillage();
        var shieldsView = new ShieldsView(village);
        shieldsView.setPositionRound(styles.shields.position);
        this.addChild(shieldsView);
        var control = new HidingNode(shieldsView, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("shields", control);
        cleverapps.aims.registerTarget("shields", control, {
            controls: "shields",
            noTargetDelta: true
        });

        if (aisensia.levelMastery.isAvailable) {
            var levelMastery = this.levelMastery = new LevelMasteryView({ type: "scene" });
            levelMastery.setPositionRound(cleverapps.styles.LevelMasteryView);
            this.addChild(levelMastery);
            levelMastery.setLocalZOrder(15);
            levelMastery.registerControl();
            levelMastery.updateSize();
        }

        this.onResize();
    },

    addSlotMachineToolbar: function () {
        var toolbarView = new ToolbarView();
        toolbarView.setLocalZOrder(1);
        this.addChild(toolbarView);

        this.downToolBarControl = new HidingNode(toolbarView, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("toolbar", this.downToolBarControl);
    },

    setupChildren: function () {
        this._super();

        if (!this.isAllLoaded) {
            return;
        }

        if (!this.slotMachineView) {
            return;
        }

        if (!this.button || !this.clouds1 || !this.clouds2 || !this.clouds3) {
            return;
        }

        var styles = cleverapps.styles.CoinKingdomScene;

        this.slotMachineView.setPosition(this.width / 2, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.slotmachine.vertical.y : styles.slotmachine.horizontal.y);
        this.button.setPosition(this.width / 2, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.button.vertical.y : styles.button.horizontal.y);

        var cloudStyles = [styles.clouds1, styles.clouds2, styles.clouds3];
        [this.clouds1, this.clouds2, this.clouds3].forEach(function (clouds, index) {
            var cloudsStyle = cloudStyles[index];
            clouds.setPosition(this.width / 2, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cloudsStyle.vertical.y : cloudsStyle.horizontal.y);
            clouds.setScale(this.width / clouds.width, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL
                ? (this.width > cleverapps.styles.SCENE_MIN_WIDTH ? this.height / styles.clouds.vertical.height : this.width / clouds.width)
                : this.height / styles.clouds.horizontal.height);

            if (cleverapps.config.debugMode) {
                clouds.debugBorder();
            }
        }.bind(this));
    },

    onExit: function () {
        this._super();

        this.slotMachine.purge();
    },

    listBundles: function () {
        var bundles = this._super() || [];

        bundles.push("transition_attack");
        bundles.push("transition_raid");

        return bundles;
    },

    _closeAction: cleverapps.FixedWidthScene.prototype._closeAction
});

GameScene = CoinKingdomScene;

cleverapps.styles.CoinKingdomScene = {
    slotmachine: {
        vertical: {
            y: 276
        },
        horizontal: {
            y: 256
        }
    },

    button: {
        vertical: {
            y: 124
        },
        horizontal: {
            y: 144
        }
    },

    clouds: {
        vertical: {
            height: 2180
        },
        horizontal: {
            height: 1080
        }
    },

    clouds1: {
        vertical: {
            y: 610
        },
        horizontal: {
            y: 560
        }
    },

    clouds2: {
        vertical: {
            y: 384
        },
        horizontal: {
            y: 494
        }
    },

    clouds3: {
        vertical: {
            y: 184
        },
        horizontal: {
            y: 458
        }
    },

    shields: {
        position: [
            {
                x: { align: "center" },
                y: { align: "top", dy: -156 }
            },
            {
                x: { align: "center" },
                y: { align: "top", dy: -80 }
            },
            {
                x: { align: "center" },
                y: { align: "top", dy: -80 }
            }
        ]
    }
};