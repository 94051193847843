/**
 * Created by Mikhail Menshenin on 18.07.2024
 */

NotificationView.prototype.ctor = cleverapps.extendFunc(NotificationView.prototype.ctor, function (model, message, options) {
    this._super(model, message, options);

    if (options.progress) {
        this.bar.options.barText.dy = 2;
        this.bar.updateBarTextPosition();
    }
});