/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

SideBar.SLOTS = [{
    row: 0,
    col: SideBar.LEFT_COLUMN
}, {
    row: 0,
    col: SideBar.RIGHT_COLUMN
}, {
    row: 1,
    col: SideBar.LEFT_COLUMN
}, {
    row: 1,
    col: SideBar.RIGHT_COLUMN
}, {
    row: 2,
    col: SideBar.LEFT_COLUMN
}, {
    row: 2,
    col: SideBar.RIGHT_COLUMN
}, {
    row: 3,
    col: SideBar.LEFT_COLUMN
}, {
    row: 3,
    col: SideBar.RIGHT_COLUMN
}, {
    row: 4,
    col: SideBar.LEFT_COLUMN
}, {
    row: 4,
    col: SideBar.RIGHT_COLUMN
}];
