/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var SlotMachineCollectionsToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.SLOTMACHINE_COLLECTIONS);

    this.enable();
};

SlotMachineCollectionsToolbarItem.prototype = Object.create(ToolbarItem.prototype);
SlotMachineCollectionsToolbarItem.prototype.constructor = ToolbarItem;

SlotMachineCollectionsToolbarItem.prototype.onClick = function () {
    console.log("SLOTMACHINE COLLECTIONS click");
};

SlotMachineCollectionsToolbarItem.prototype.isVisible = function () {
    return true;
};
