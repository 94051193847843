/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var Village = function (config, player) {
    cleverapps.EventEmitter.call(this);

    this.shields = 0;
    this.gold = 0;
    this.attention = 0;
    this.level = config.level;
    this.state = Village.STATE_BUILD;
    this.progress = 0;
    this.cost = Village.calculateVillageTotalCost(this.level);

    this.player = player || this.getCurrentPlayer();
    this.name = config.name;
    this.huts = config.huts ? config.huts.map(function (hutData, index) {
        return new Hut(this, hutData, index);
    }, this) : [];

    this.crosses = config.crosses ? config.crosses.map(function (data, index) {
        return new Cross(this, data, index);
    }, this) : [];

    this.checkAttention();
    this.huts.forEach(function (hut) {
        hut.on("changeUpgradeable", this.checkAttention.bind(this));
    }.bind(this));
};

Village.prototype = Object.create(cleverapps.EventEmitter.prototype);
Village.prototype.constructor = Village;

Village.prototype.getBundleId = function () {
    return "village_" + this.name;
};

Village.prototype.openUpgradeWindow = function () {
    cleverapps.meta.display({
        focus: "VillageUpgradeWindow",
        control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
        action: function (f) {
            new VillageUpgradeWindow(this);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};

Village.prototype.getInfo = function () {
    var huts = this.huts.reduce(function (huts, hut) {
        var info = hut.getInfo();
        if (info) {
            huts[hut.name] = info;
        }
        return huts;
    }, {});

    var crosses = this.crosses.map(function (cross) {
        return cross.getInfo();
    }, {});

    return {
        huts: huts,
        crosses: crosses,
        shields: this.shields,
        raids: this.raids,
        gold: this.gold,
        goldRaided: this.goldRaided
    };
};

Village.prototype.getState = function () {
    return this.state
}

Village.prototype.save = function () {
    aisensia.villageManager.save(this);
};

Village.prototype.load = function (data) {
    if (!data) {
        return;
    }

    this.shields = data.shields || this.shields;
    this.gold = data.gold || this.gold;
    this.goldRaided = data.goldRaided || this.goldRaided;
    this.state = data.state || this.state;

    var hutNames = data.huts && Object.keys(data.huts) || [];
    hutNames.forEach(function (hutName) {
        var hutData = data.huts[hutName];
        var hut = this.huts.find(function (hut) {
            return hut.name === hutName;
        });
        if (hut) {
            hut.load(hutData);
        }
    }.bind(this));

    var crosses = data.crosses || [];
    crosses.forEach(function (cross, index) {
        this.crosses[index].raided = cross.raided;
    }.bind(this));

    this.raids = data.raids || this.raids;
    this.progress = this.getProgress();
};

Village.prototype.getCurrentPlayer = function () {
    var playerInfo = cleverapps.user.getPlayerInfo();

    return {
        player: true,
        id: connector.platform.getUserID(),
        name: playerInfo.name,
        avatar: playerInfo.avatar
    };
};

Village.prototype.setShields = function (shields) {
    this.shields = shields;

    if (this.shields > Village.MAX_SHIELDS) {
        this.shields = Village.MAX_SHIELDS;
    }

    this.save();

    this.trigger("changeShields");
};

Village.prototype.prepareToAttack = function (options) {
    this.state = Village.STATE_ATTACK;

    this.huts.forEach(function (hut) {
        hut.attacked = false;
    });
};

Village.prototype.prepareToRaid = function (options) {
    this.state = Village.STATE_RAID;
    this.raids = options && options.raids || Village.MAX_RAIDS;
    this.goldRaided = options && options.goldRaided || 0;

    this.crosses.forEach(function (cross, index) {
        cross.raided = options && options.crosses[index].raided || false;
    });
};

Village.prototype.getProgress = function () {
    return this.huts.reduce(function (progress, hut) {
        return progress + hut.getCurrentLevel();
    }, 0);
};

Village.prototype.getGoal = function () {
    return this.huts.reduce(function (goal, hut) {
        return goal + hut.getMaxLevel();
    }, 0);
};

Village.prototype.checkAttention = function () {
    var attention = this.huts.reduce(function (attention, hut) {
        return attention + (hut.upgradeable ? 1 : 0);
    }, 0);

    if (this.attention !== attention) {
        this.attention = attention;
        this.trigger("changeAttention");
    }
};

Village.prototype.attack = function () {
    this.trigger("attacked");
};

Village.prototype.raid = function (gold) {
    if (!this.isStateRaid()) {
        return;
    }

    if (!this.raids) {
        return;
    }

    this.raids--;
    this.goldRaided += gold;

    this.trigger("changeRaids");
    this.trigger("changeGoldRaided");
};

Village.prototype.updateProgress = function () {
    var progress = this.getProgress();

    if (this.progress !== progress) {
        this.progress = progress;

        this.save();

        this.trigger("changeProgress", this.checkGoal.bind(this));
    }
};

Village.prototype.checkGoal = function () {
    var goal = this.getGoal();

    if (this.progress >= goal) {
        aisensia.villageManager.moveNext();
    }
};

Village.prototype.isRaidFinished = function () {
    return !this.raids;
};

Village.prototype.getGoldByRaid = function () {
    var maxGoldEarn = (this.gold - this.goldRaided) / this.raids;
    var gold = cleverapps.Random.random(0, Math.floor(maxGoldEarn / 1000)) * 1000;

    return gold;
};

Village.prototype.isStateBuild = function () {
    return this.state === Village.STATE_BUILD;
}

Village.prototype.isStateAttack = function () {
    return this.state === Village.STATE_ATTACK;
}

Village.prototype.isStateRaid = function () {
    return this.state === Village.STATE_RAID;
}

Village.calculateVillageTotalCost = function (levelNo) {
    if (levelNo < 20) {
        return 3900000 + levelNo * 4500000;
    }

    levelNo = levelNo + 1;
    return -37100000 + 17400000 * levelNo - 1140000 * Math.pow(levelNo, 2) + 35467 * Math.pow(levelNo, 3) - 466 * Math.pow(levelNo, 4) + 2.87 * Math.pow(levelNo, 5) - 0.00434 * Math.pow(levelNo, 6)
};

Village.MAX_SHIELDS = 3;
Village.MAX_RAIDS = 3;

Village.STATE_BUILD = 1;
Village.STATE_ATTACK = 2;
Village.STATE_RAID = 3;