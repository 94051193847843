/**
 * Created by Slava on 02.07.2024
 */

DailyTasksWindow.prototype.onWindowLoaded = cleverapps.extendFunc(DailyTasksWindow.prototype.onWindowLoaded, function () {
    this._super();

    this.foreground.removeFromParent();
});

DailyTasksWindow.prototype.createContent = cleverapps.extendFunc(DailyTasksWindow.prototype.createContent, function () {
    this._super();

    var timer = new DailyTaskTimer(window.close.bind(window));

    timer.setPositionRound(cleverapps.styles.DailyTasksWindow.timer);
    this.content.addChild(timer);
});