/**
 * Created by Slava on 04.09.2024
 */

Snapshots.extractors.village = function () {
    var scene = cleverapps.scenes.getRunningScene();
    if (scene.village) {
        return scene.village.getInfo();
    }
};

Snapshots.extractors.scene = function () {
    if (Game.currentGame && Game.currentGame.level && Game.currentGame.level.episodeNo !== undefined && Game.currentGame.level.levelNo !== undefined) {
        return {
            episodeNo: Game.currentGame.level.episodeNo,
            levelNo: Game.currentGame.level.levelNo
        };
    }
    var scene = cleverapps.scenes.getRunningScene();
    if (scene instanceof VillageScene) {
        return {
            villageState: scene.village.getState()
        }
    }
};

Snapshots.importers.village = function (data) {
    aisensia.savedVillage = data;
};