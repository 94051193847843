/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageScene = cleverapps.FixedWidthScene.extend({
    ctor: function () {
        this._super();

        this.village = this.getVillage();
    },

    onSceneLoaded: function (sceneName) {
        this._super(sceneName || cleverapps.Environment.SCENE_MAIN);

        cleverapps.environment.levelNo = levels.user.level;
        cleverapps.environment.episodeNo = levels.user.episode;

        cleverapps.placements.run(Placements.INTERMEDIATE);

        this.addVillage();

        this.addUI();

        this.upMenuContainer.avoidNode = "village";
        this.addAvoidNode(this.upMenuContainer);

        this.updateSize();

        if (cleverapps.config.debugMode) {
            cleverapps.git.changeFolders(GitManager.FOLDERS.VILLAGES);
        }

        if (aisensia.savedVillage) {
            aisensia.savedVillage = undefined;
        }
    },

    updateSize: function () {
        this._super();

        var styles = cleverapps.styles.VillageScene;

        if (this.title) {
            var padding = cc.padding(styles.title.padding);
            cleverapps.UI.fitToBox(this.title, {
                width: this.width - padding.left - padding.right,
                height: styles.title.height
            });
            this.title.setPositionRound(styles.title.position);
        }
    },

    addVillage: function () {
        var village = this.villageView = new VillageView(this.village);
        this.background.addChild(village);

        var control = new HidingNode(village, HidingNode.DIR.NONE);
        cleverapps.meta.registerControl("village", control);
        control.show();

        return control;
    },

    addUI: function () {
        this.addProgress();

        var styles = cleverapps.styles.VillageScene;

        var hammer = new VillageUpgradeToolbarItemView(new VillageUpgradeToolbarItem(this.village));
        hammer.avoidNode = "hammer";
        hammer.setPositionRound(styles.hammer.position);
        this.addChild(hammer);

        this.title = new VillageTitleView(this.village);
        this.title.setPositionRound(styles.title.position);
        this.addChild(this.title);

        var shieldsView = new ShieldsView(this.village);
        shieldsView.setPositionRound(styles.shields.position);
        this.addChild(shieldsView);
    },

    addProgress: function () {
        var styles = cleverapps.styles.VillageScene;
        var progress = new VillageProgressView(this.village);
        progress.setPositionRound(styles.progress.position);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            progress.setScale(0.8);
        }
        this.addChild(progress);

        var control = new HidingNode(progress, HidingNode.DIR.NONE);
        cleverapps.meta.registerControl("progress", control);

        return control;
    },

    getBackgroundStyles: function () {
        var bundleName = cleverapps.simple.getBackgroundBundle(this.village.level);
        return {
            bundle: bundleName,
            backgroundId: "background"
        };
    },

    getVillage: function () {
        if (aisensia && aisensia.villageManager) {
            return aisensia.villageManager.getVillage();
        }
    },

    listBundles: function () {
        var bundles = [
            cleverapps.simple.getBackgroundBundle(cleverapps.simple.current)
        ];

        if (this.village) {
            bundles.push(cleverapps.simple.getBackgroundBundle(this.village.level));
            bundles.push(this.village.getBundleId());
        }

        return bundles;
    },

    _closeAction: MainScene.prototype._closeAction
});

cleverapps.styles.VillageScene = {
    title: {
        height: 80,
        padding: {
            x: 60
        },
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 124 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 170 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 170 }
        }]
    },

    hammer: {
        position: [{
            x: { align: "left", dx: 10 },
            y: { align: "bottom", dy: 10 }
        }, {
            x: { align: "left", dx: 80 },
            y: { align: "bottom", dy: 24 }
        }, {
            x: { align: "left", dx: 80 },
            y: { align: "bottom", dy: 24 }
        }]
    },

    progress: {
        position: [
            {
                x: { align: "center" },
                y: { align: "top", dy: -112 }
            }, {
                x: { align: "center" },
                y: { align: "top", dy: -18 }
            }, {
                x: { align: "center" },
                y: { align: "top", dy: -18 }
            }
        ]
    },

    shields: {
        position: [
            {
                x: { align: "right", dx: -160 },
                y: { align: "top", dy: -52 }
            },
            {
                x: { align: "right", dx: -340 },
                y: { align: "top", dy: -60 }
            },
            {
                x: { align: "right", dx: -340 },
                y: { align: "top", dy: -60 }
            }
        ]
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat(["hammer", "VillageTitle", "village"]);