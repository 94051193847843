/**
 * Created by Mikhail Menshenin on 24.04.2024
 */

var LevelMasteryMissionLogic = function (mission) {
    this.mission = mission;
};

LevelMasteryMissionLogic.prototype.getPrize = function () {
    var stage = aisensia.levelMastery.stage;
    var stages = this.mission.stages;
    var prize = stages[stage];

    return prize;
};

LevelMasteryMissionLogic.prototype.skipNext = function () {
    return aisensia.levelMastery.isStageCompleted();
};
