/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

var OfferIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.offer_json,
        targets: ["OfferIcon"],
        control: "OfferIcon",
        hiddenByDefault: Object.values(cleverapps.Environment).filter(function (scene) {
            return ![cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_SLOT_MACHINE].includes(scene);
        })
    });

    this.resetState();
};

OfferIcon.prototype = Object.create(SideBarIcon.prototype);
OfferIcon.prototype.constructor = OfferIcon;

OfferIcon.prototype.resetState = function () {
    this.available = true;
};
