/**
 * Created by Mikhail Menshenin on 24.04.2024
 */

Mission.TYPE_LEVEL_MASTERY = 102;

MissionManager.REGISTER_MISSION(Mission.TYPE_LEVEL_MASTERY, {
    available: {
        level: 0.1,
        debugMode: true
    },
    name: "LevelMastery",
    view: LevelMasteryWindow,
    stages: [
        {
            goal: 2,
            prize: {
                simpleStar: 1
            }
        },
        {
            goal: 3,
            prize: {
                soft: 50
            }
        },
        {
            goal: 5,
            prize: {
                hard: 5
            }
        },
        {
            goal: 3,
            prize: {
                soft: 50
            }
        },
        {
            goal: 5,
            prize: {
                soft: 100
            }
        },
        {
            goal: 4,
            prize: {
                simpleStar: 2
            }
        },
        {
            goal: 10,
            prize: {
                hard: 10
            }
        },
        {
            goal: 3,
            prize: {
                soft: 150
            }
        },
        {
            goal: 6,
            prize: {
                simpleStar: 3
            }
        },
        {
            goal: 7,
            prize: {
                soft: 200
            }
        },
        {
            goal: 5,
            prize: {
                soft: 200
            }
        },
        {
            goal: 20,
            prize: {
                hard: 25
            }
        },
        {
            goal: 3,
            prize: {
                soft: 100
            }
        },
        {
            goal: 6,
            prize: {
                simpleStar: 3
            }
        },
        {
            goal: 9,
            prize: {
                soft: 150
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 7,
            prize: {
                simpleStar: 5
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 25,
            prize: {
                hard: 75
            }
        },
        {
            goal: 5,
            prize: {
                soft: 100
            }
        },
        {
            goal: 6,
            prize: {
                simpleStar: 3
            }
        },
        {
            goal: 9,
            prize: {
                soft: 150
            }
        },
        {
            goal: 7,
            prize: {
                simpleStar: 5
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 30,
            prize: {
                hard: 200
            }
        }
    ],
    startWindow: undefined,
    nudgeWindow: undefined,
    duration: "3 days",
    semaphore: Missions.SEMAPHORE_ALWAYS,
    bundle: "levelmastery",
    sideBarJson: false,
    logic: LevelMasteryMissionLogic,
    manualStart: function () {
        return true;
    }
});

MissionManager.prototype.add = cleverapps.extendFunc(MissionManager.prototype.add, function (type, data, fromServer) {
    var mission = this._super(type, data, fromServer);

    if (type === Mission.TYPE_LEVEL_MASTERY) {
        if (aisensia.levelMastery && !aisensia.levelMastery.isAvailable) {
            aisensia.levelMastery.setMission(mission);
        }
    }

    return mission;
});

GameBase.REWARD_LEVEL_MASTERY = "levelMastery";
RewardTypes[GameBase.REWARD_LEVEL_MASTERY] = {
    flyingAnimation: Reward.CUSTOM_COLLECT,

    getIcon: function () {
        return bundles.levelmastery.frames.cup_png;
    },

    handler: function () { }
};

Placements.prototype.initialize = cleverapps.extendFunc(Placements.prototype.initialize, function () {
    var placements = this._super();

    placements.levelMasteryRewardCollect = {
        type: Placements.FREE_FOCUS_MAIN,
        priority: 1000,
        filter: function () {
            return aisensia.levelMastery && aisensia.levelMastery.isAvailable && aisensia.levelMastery.isStageCompleted();
        },
        action: function () {
            var scene = cleverapps.scenes.getRunningScene();
            cleverapps.meta.display({
                focus: "LevelMasteryReward",
                control: ["levelmastery_progress"],
                actions: [
                    function (f) {
                        scene.levelMastery.rewardAnimation(f);
                    },
                    function (f) {
                        aisensia.levelMastery.updateStage();

                        scene.levelMastery.nextRewardAnimation(function () {
                            f();
                        });
                    }
                ]
            });
        }
    };

    return placements;
});

MissionsTool.getMissionMenu = cleverapps.extendFunc(MissionsTool.getMissionMenu, function (mission) {
    var missionActions = this._super(mission);

    if (mission.type === Mission.TYPE_LEVEL_MASTERY) {
        missionActions.addLevel = function () {
            aisensia.levelMastery.level += 1;
            aisensia.levelMastery.save();

            aisensia.levelMastery.trigger("updateLevel", function () {
                if (cleverapps.placements.data.levelMasteryRewardCollect.filter()) {
                    cleverapps.placements.data.levelMasteryRewardCollect.action();
                }
            });
        };

        missionActions.setStage = function () {
            var stage = parseInt(window.prompt("Level Mastery stage (1..." + (aisensia.levelMastery.getMaxStage() + 1) + "):")) - 1;
            aisensia.levelMastery.level = 0;
            aisensia.levelMastery.stage = stage;
            aisensia.levelMastery.save();
            aisensia.levelMastery.trigger("updateLevel");
            aisensia.levelMastery.trigger("updateStage", true);
        };

        missionActions.reset = function () {
            aisensia.levelMastery.level = 0;
            aisensia.levelMastery.stage = 0;
            aisensia.levelMastery.save();
            aisensia.levelMastery.trigger("updateLevel");
            aisensia.levelMastery.trigger("updateStage", true);
        };

        missionActions.collectReward = function () {
            cleverapps.placements.data.levelMasteryRewardCollect.action();
        };
    }

    return missionActions;
});