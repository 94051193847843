/**
 * Created by Mikhail Menshenin on 21.08.2024
 */

aisensia.getNumberWithCommas = function (number) {
    number = parseInt(number).toString();
    var separeted = "";

    while (number.length > 3) {
        separeted = "," + number.slice(-3) + separeted;
        number = number.slice(0, -3);
    }

    separeted = number + separeted;

    return separeted;
};
