/**
 * Created by Slava on 02.07.2024
 */

Timer.prototype.ctor = cleverapps.extendFunc(Timer.prototype.ctor, function (timeLeft, onFinish, options) {
    this._super(timeLeft, onFinish, options);

    options = options || {};

    var styles = cleverapps.styles.Timer;

    var width = options.width || styles.width;
    var height = styles.height;

    var bg = cleverapps.UI.createScale9Sprite(bundles.windows.frames.window_bottom_panel_png);
    bg.setContentSize2(width, height);

    var countDown = this.countDown = timeLeft <= 0 ? timeLeft : new cleverapps.CountDown(timeLeft, {
        onFinish: onFinish
    });

    var countDownView = new cleverapps.CountDownView(countDown, {
        font: cleverapps.styles.FONTS.TIMER_TEXT
    });
    bg.addChild(countDownView);
    countDownView.setPositionRound(styles.timer);
    countDownView.fitTo(width - 2 * styles.padding.x, height - 2 * styles.padding.y);

    this.setItems([bg]);
});