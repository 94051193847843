/**
 * Created by mac on 7/23/23
 */

var projects = {
    debug: {},
    terms: {},
    samsung: {},

    yatzy: {
        development: true,
        type: "none",
        meta: "simple",
        webhook: ["aisensia"],
        orientation: "vertical",
        deployment: "",
        title: "Yatzy",
        levelsPath: "res/levels",
        lives: true,
        languages: ["en"],
        ui: "playrix",
        uiPath: "aisensia/res/ui/playrix/",
        persons: {
            main: {
                hero: "mergecraft_dwarf_hogrider_redesign"
            }
        },
        personsMinimal: {
            hero: "mergecraft_dwarf_hogrider_minimal"
        },
        features: [
            "aisensiacommon",
            "yatzyengine",
            "simplealtview",
            "badge_collection"
        ],
        path: "aisensia/",
        stand: {
            staticUrl: "https://yatzy-staging.aisensia.com/publish/html5-staging/",
            deployment: "https://yatzy-staging.aisensia.com"
        },
        instant: {
            appId: "some",
            groupId: "test"
        },
        bundles: [
            "/topack/yatzybundles"
        ]
    },
    coinkingdom: {
        development: true,
        type: "none",
        meta: "loop",
        webhook: ["aisensia"],
        orientation: "vertical",
        deployment: "",
        title: "Coin Kingdom: spin master",
        levelsPath: "res/levels",
        lives: true,
        soft: false,
        languages: ["en"],
        ui: "wooden",
        stand: {
            staticUrl: "",
            deployment: ""
        },
        features: [
            "map2d",
            "slotmachine"
        ],
        path: "aisensia/",
        bundles: [
            "/topack/fishdombundles",
            "/topack/coinsbundles",
            "/topack/coinkingdombundles"
        ]
    },
    jam: {
        development: true,
        norest: true,
        type: "none",
        meta: "loop",
        webhook: ["aisensia"],
        orientation: "vertical",
        deployment: "",
        title: "Jam",
        levelsPath: "res/levels",
        lives: true,
        languages: [
            "en"
        ],
        ui: "riddles",
        persons: {},
        features: [],
        path: "aisensia/",
        stand: {
            staticUrl: "",
            deployment: ""
        },
        instant: {
            appId: "some",
            groupId: "test"
        },

        bundles: ["/topack/jambundles"]
    }
};

(function insertPorts() {
    var ports = {
        "coinkingdom": 3101,
        "yatzy": 3102,
        "jam": 3103
    };

    Object.keys(ports).forEach(function (name) {
        projects[name].restPort = ports[name];
    });
}());

if (typeof exports !== "undefined") {
    module.exports = projects;
}
