ToolbarItem.VILLAGE_UPGRADE = {
    type: 101,
    icon: function () {
        return {
            sprite: bundles.buttons.frames.button_green_bg
        }
    }
};

ToolbarItem.SLOTMACHINE_COLLECTIONS = {
    type: 102,
    icon: bundles.buttons.frames.collections_png
}