/**
 * Created by Slava on 27.06.2024.
 */

Products.gold500.reward = { hard: 200 };
Products.gold1800.reward = { hard: 900 };
Products.gold3800.reward = { hard: 2100 };
Products.gold6000.reward = { hard: 3500 };
Products.gold25000.reward = { hard: 14000 };

cleverapps.override(Products, {
    starterPack0: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack0.Title"
        },
        reward: RewardsConfig.Packs[0],
        stage: 0,
        mainSceneOnly: true
    },
    starterPack: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack1.Title"
        },
        reward: RewardsConfig.Packs[1],
        stage: 1,
        mainSceneOnly: true
    },
    starterPack2: {
        boughtMessage: "StarterPack.Bought",
        boughtMessageParams: {
            title: "StarterPack2.Title"
        },
        reward: RewardsConfig.Packs[2],
        stage: 2,
        mainSceneOnly: true
    }
});