/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var HutView = cc.Node.extend({
    avoidNode: "HutView",

    ctor: function (hut) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.HutView;
        this.hut = hut;

        var image = this.image = new cc.Sprite(bundles[hut.village.getBundleId()].frames[hut.getFrameId(1)]);
        this.setContentSize(image.getContentSize());
        image.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(image);
        image.setVisible(false);

        if (hut.village.isStateBuild()) {
            var icon = this.icon = new cc.Sprite(bundles.village.frames.build_png);
            icon.setPositionRound(styles.icon);
            this.addChild(icon);
        }

        cleverapps.UI.onClick(this, this.createListener(this.hut.onClick.bind(this.hut)), { interactiveScale: false });

        if (hut.canAttack()) {
            this.targetIcon = new cc.Sprite(bundles.village.frames.target_png);
            this.targetIcon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
            this.addChild(this.targetIcon);
            this.targetIcon.setScale(0.1);
            this.targetIcon.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(0.5 + 0.1 * hut.id),
                new cc.Show(),
                cleverapps.UI.animateScale({
                    duration: 0.1,
                    scale: 1,
                    overScaling: 1.2
                })
            ));
        }

        this.onResize();

        this.hut.on("changeLevel", this.animateLevelUp.bind(this));
        this.hut.on("changeDamage", this.animateDamage.bind(this));
        this.hut.on("changeUpgradeable", this.checkUpgradeable.bind(this));
        this.checkUpgradeable();

        if (cleverapps.config.debugMode) {
            this.level = cleverapps.UI.generateImageText(this.hut.getCurrentLevel(), cleverapps.styles.FONTS.HUT_LEVEL_TEXT);
            this.level.setPositionRound({ x: { align: "right" }, y: { align: "top" } });
            this.addChild(this.level);

            this.damage = cleverapps.UI.generateImageText(this.hut.damage, cleverapps.styles.FONTS.HUT_DAMAGE_TEXT);
            this.damage.setPositionRound({ x: { align: "left" }, y: { align: "top" } });
            this.addChild(this.damage);

            var checkLevelDebug = function () {
                this.level.setVisible(!!cleverapps.flags.hutLevelDebug);
                this.damage.setVisible(!!cleverapps.flags.hutLevelDebug);
            }.bind(this);
            cleverapps.flags.on("change:hutLevelDebug", checkLevelDebug);
            checkLevelDebug();
        }

        this.animateLevelUp();
    },

    updateSize: function () {
        var styles = cleverapps.styles.HutView;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.setScale(1);
        } else {
            this.setScale(0.72);
        }

        this.setPositionRound(typeof this.hut.position == "number" ? styles.positions[this.hut.position] : this.hut.position);
    },

    animateLevelUp: function () {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        if (cleverapps.config.debugMode) {
            this.level.setString(this.hut.getCurrentLevel());
            this.damage.setString(this.hut.damage);
        }

        if (!this.image.visible && this.hut.isVisible()) {
            this.setFrame(newFrame);
            this.image.setVisible(true);
            if (this.icon) {
                this.icon.setVisible(!this.hut.isComplete());
            }
            return;
        }

        if (!this.hut.isVisible()) {
            this.image.setVisible(false);
            return;
        }

        this.image.runAction(
            cc.sequence(
                cc.scaleTo(0.4, 0.6),
                cc.callFunc(function () {
                    this.setFrame(newFrame);
                    if (this.hut.isComplete()) {
                        this.icon.setVisible(false);
                    }
                }.bind(this)),
                cc.scaleTo(0.4, 1)
            )
        );
    },

    animateDamage: function () {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        if (cleverapps.config.debugMode) {
            this.level.setString(this.hut.getCurrentLevel());
            this.damage.setString(this.hut.damage);
        }

        if (this.targetIcon && !this.hut.canAttack()) {
            this.targetIcon.removeFromParent();
        }

        this.image.runAction(
            cc.sequence(
                cc.scaleTo(0.4, 0.6),
                cc.callFunc(function () {
                    this.setFrame(newFrame);
                }.bind(this)),
                cc.scaleTo(0.4, 1)
            )
        );
    },

    setFrame: function (frame) {
        this.image.setSpriteFrame(frame);
        this.setContentSize(this.image.getContentSize());
    },

    checkUpgradeable: function () {
        if (this.icon) {
            if (this.hut.upgradeable) {
                this.icon.setSpriteFrame(bundles.village.frames.build_png);
            } else {
                this.icon.setSpriteFrame(bundles.village.frames.build_grey_png);
            }
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HUT_LEVEL_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },
    HUT_DAMAGE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.HutView = {
    icon: {
        x: { align: "center" },
        y: { align: "bottom" }
    },

    positions: [
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 400 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 200 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: 308 },
            y: { align: "center", dy: -90 }
        }, {
            x: { align: "center", dx: 170 },
            y: { align: "center", dy: -50 }
        }, {
            x: { align: "center", dx: 170 },
            y: { align: "center", dy: -50 }
        }],
        [{
            x: { align: "center", dx: 250 },
            y: { align: "center", dy: -1000 }
        }, {
            x: { align: "center", dx: -250 },
            y: { align: "center", dy: -520 }
        }, {
            x: { align: "center", dx: -250 },
            y: { align: "center", dy: -520 }
        }]
    ],

    attention: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 },

        scale: 0.5
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("HutView");