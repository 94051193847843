/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        this.village = village;

        this.hutViews = village.huts.map(function (hut) {
            var hutView = new HutView(hut);
            this.addChild(hutView);
            return hutView;
        }.bind(this));

        this.crossViews = village.crosses.map(function (cross) {
            var crossView = new CrossView(cross);
            this.addChild(crossView);
            return crossView;
        }.bind(this));

        this.updateSize();

        if (cleverapps.config.debugMode) {
            var shieldText = this.shieldText = cleverapps.UI.generateImageText(this.village.shields, cleverapps.styles.FONTS.VILLAGE_SHIELD_TEXT);
            shieldText.setPositionRound({ x: { align: "left", dx: 100 }, y: { align: "top", dy: -100 } });
        }

        this.village.on("changeShields", this.createListener(this.updateShields.bind(this)));
    },

    updateSize: function () {
        var scene = cleverapps.scenes.getRunningScene();
        this.setContentSize(scene.background.getContentSize());
    },

    updateShields: function () {
        if (cleverapps.config.debugMode) {
            this.shieldText.setString(this.village.shields);
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_SHIELD_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_BLUE
    }
});