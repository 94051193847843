/**
 * Created by Slava on 02.07.2024
 */

DailyTaskTimer.prototype.ctor = cleverapps.extendFunc(DailyTaskTimer.prototype.ctor, function (onFinish, onBg) {
    this._super(onFinish, onBg);
    var timer = new Timer(cleverapps.dailyTasks.getTimeLeft(), onFinish);

    this.setCascadeOpacityEnabled(true);
    timer.setCascadeOpacityEnabled(true);

    this.setItems([timer]);
});