/**
* Created by Mikhail Menshenin on 13.03.2024
*/

var LevelMastery = function () {
    cleverapps.EventEmitter.call(this);

    this.isAvailable = false;
};

LevelMastery.prototype = Object.create(cleverapps.EventEmitter.prototype);

LevelMastery.prototype.setMission = function (mission) {
    if (mission.isCompleted()) {
        return;
    }

    this.mission = mission;
    this.isAvailable = true;

    this.init();
};

LevelMastery.prototype.init = function () {
    var data = this.mission && this.mission.details || {};
    this.level = data && data.level || 0;
    this.stage = data && data.stage || 0;
    this.stages = this.mission.stages;

    if (this.stage >= this.stages.length) {
        this.isAvailable = false;
        this.mission.complete();
    }
};

LevelMastery.prototype.getTimeLeft = function () {
    return this.mission.getTimeLeft();
};

LevelMastery.prototype.isCompleted = function (stage) {
    return stage < this.stage;
};

LevelMastery.prototype.isCurrent = function (stage) {
    return stage === this.stage;
};

LevelMastery.prototype.isLastStage = function (stage) {
    return stage === this.stages.length - 1;
};

LevelMastery.prototype.getStageRewards = function () {
    return this.stages.map(function (stage) {
        return this.getStageReward(stage);
    }.bind(this));
};

LevelMastery.prototype.getCurrentReward = function () {
    var stage = this.getCurrentStage();

    return this.getStageReward(stage);
};

LevelMastery.prototype.getStageReward = function (stage) {
    var prize = stage.prize;
    var type = Object.keys(prize)[0];
    var value = prize[type];

    return new Reward(type, value, {});
};

LevelMastery.prototype.getMaxLevel = function () {
    var stage = this.getCurrentStage();

    return stage.goal;
};

LevelMastery.prototype.getCurrentStage = function () {
    return this.getStage(this.stage);
};

LevelMastery.prototype.getStage = function (stage) {
    return this.stages[stage];
};

LevelMastery.prototype.getMaxStage = function () {
    return this.stages.length - 1;
};

LevelMastery.prototype.updateStage = function () {
    this.stage += 1;
    this.level = 0;

    if (this.stage >= this.stages.length) {
        this.isAvailable = false;
        this.mission.complete();
    }

    this.save();

    if (this.isAvailable) {
        this.trigger("updateStage");
    }
};

LevelMastery.prototype.isStageCompleted = function () {
    var maxLevel = this.getMaxLevel();
    return this.level >= maxLevel;
};

LevelMastery.prototype.gamePlayed = function (outcome, game) {
    if (!this.isAvailable) {
        return;
    }
    if (outcome === GameBase.OUTCOME_VICTORY) {
        this.level += 1;
        game.rewards[GameBase.REWARD_LEVEL_MASTERY] = 1;
    } else if (outcome === GameBase.OUTCOME_LOST) {
        this.level = 0;
    }

    this.save();
};

LevelMastery.prototype.getInfo = function () {
    return {
        level: this.level,
        stage: this.stage
    };
};

LevelMastery.prototype.save = function () {
    this.mission.update(0, this.getInfo());
};

cleverapps.whenAllInitialized(
    function () {
        aisensia.levelMastery = new LevelMastery();

        var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_LEVEL_MASTERY);

        if (mission) {
            aisensia.levelMastery.setMission(mission);
        }
    }
);
