/**
 * Created by Mikhail Menshenin on 02.07.2024
 */

DailyTasks.GET_SCORE = 101;
DailyTasks.MAKE_ROLL = 102;
DailyTasks.MERGE_MEDALS = 103;
DailyTasks.MAKE_FOUR_OF_A_KIND = 105;
DailyTasks.MAKE_FULL_HOUSE = 106;
DailyTasks.MAKE_SMALL_STRAIGHT = 107;
DailyTasks.MAKE_LARGE_STRAIGHT = 108;
DailyTasks.MAKE_YATZY = 109;
DailyTasks.RECEIVE_BONUS = 110;
DailyTasks.GET_HIGH_SCORE = 111;

DailyTasks.CONFIG = {};

DailyTasks.CONFIG[DailyTasks.WATCH_ADS] = {
    generate: function () {
        if (!connector.ads.isRewardedAvailable || cleverapps.paymentsHistory.isVIP()) {
            return;
        }

        return {
            goal: 10,
            difficulty: DailyTask.EASY
        };
    },

    icon: "daily_task_icon_watch_ads_png"
};

DailyTasks.CONFIG[DailyTasks.PASS_LEVEL] = {
    generate: function () {
        return {
            goal: 3,
            difficulty: DailyTask.EASY
        };
    },

    icon: "daily_task_icon_pass_level_png"
};

DailyTasks.CONFIG[DailyTasks.GET_SCORE] = {
    generate: function () {
        return {
            goal: 3,
            difficulty: DailyTask.EASY
        };
    },

    icon: "daily_task_icon_get_score_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_ROLL] = {
    generate: function () {
        return {
            goal: 50,
            difficulty: DailyTask.EASY
        };
    },

    icon: "daily_task_icon_make_roll_png"
};

DailyTasks.CONFIG[DailyTasks.MERGE_MEDALS] = {
    generate: function () {
        return {
            goal: 3,
            difficulty: DailyTask.EASY
        };
    },

    icon: "daily_task_icon_merge_medals_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_FOUR_OF_A_KIND] = {
    generate: function () {
        return {
            goal: 10,
            difficulty: DailyTask.MEDIUM
        };
    },

    icon: "daily_task_icon_make_four_of_a_kind_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_FULL_HOUSE] = {
    generate: function () {
        return {
            goal: 10,
            difficulty: DailyTask.MEDIUM
        };
    },

    icon: "daily_task_icon_make_full_house_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_SMALL_STRAIGHT] = {
    generate: function () {
        return {
            goal: 10,
            difficulty: DailyTask.MEDIUM
        };
    },

    icon: "daily_task_icon_make_small_straight_png"
};

DailyTasks.CONFIG[DailyTasks.GET_HIGH_SCORE] = {
    generate: function () {
        return {
            goal: 10,
            difficulty: DailyTask.MEDIUM
        };
    },

    icon: "daily_task_icon_get_high_score_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_LARGE_STRAIGHT] = {
    generate: function () {
        return {
            goal: 15,
            difficulty: DailyTask.HARD
        };
    },

    icon: "daily_task_icon_make_large_straight_png"
};

DailyTasks.CONFIG[DailyTasks.MAKE_YATZY] = {
    generate: function () {
        return {
            goal: 15,
            difficulty: DailyTask.HARD
        };
    },

    icon: "daily_task_icon_make_yatzy_png"
};

DailyTasks.CONFIG[DailyTasks.RECEIVE_BONUS] = {
    generate: function () {
        return {
            goal: 15,
            difficulty: DailyTask.HARD
        };
    },

    icon: "daily_task_icon_receive_bonus_png"
};

DailyTasks.CONFIG[DailyTasks.COMPLETE_ALL] = {
    icon: "daily_task_icon_complete_all_png"
};
