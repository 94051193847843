/**
 * Created by Mikhail Menshenin on 06.08.2024
 */

var VillageUpgradeToolbarItemView = ToolbarItemView.extend({
    ctor: function (model) {
        this._super(model);

        this.model = model;

        var styles = cleverapps.styles.VillageUpgradeToolbarItemView;
        var attention = new DualAttention({
            model: model.village,
            key: "attention",
            event: "changeAttention"
        });
        attention.setPositionRound(styles.attention);
        this.addChild(attention);

        this.icon.setContentSize(styles.size);

        var hammer = new cc.Sprite(bundles.buttons.frames.button_hammer);
        hammer.setPositionRound(styles.hammer);
        this.addChild(hammer);

        model.village.on("changeAttention", this.updateButton.bind(this));
        this.updateButton();
    },

    updateButton: function () {
        var value = this.model.village["attention"] || false;

        if (value) {
            this.icon.setSpriteFrame(bundles.buttons.frames.button_green_bg);
        } else {
            this.icon.setSpriteFrame(bundles.buttons.frames.button_grey_bg);
        }
    }
});

cleverapps.styles.VillageUpgradeToolbarItemView = {
    size: {
        width: 158,
        height: 158
    },
    attention: {
        x: { align: "right", dx: 36 },
        y: { align: "top", dy: 36 }
    },
    hammer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    }
};