/**
 * Created by Mikhail Menshenin on 25.07.2024
 */

var VillageProgressView = cc.Node.extend({
    avoidNode: "ProgressView",

    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.VillageProgressView;

        var progressBar = this.progressBar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.green_large,
            barText: {
                dy: 2,
                font: cleverapps.styles.FONTS.VILLAGE_PROGRESS_BAR_TEXT
            }
        });
        progressBar.setLength(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.mobileWidth : styles.width);
        this.setContentSize(this.progressBar.getContentSize());
        progressBar.setGoal(village.getGoal());
        progressBar.setPercentage(village.progress);
        progressBar.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(progressBar);

        var progressBuildIcon = new cc.Sprite(bundles.village.frames.progress_build_png);
        progressBuildIcon.setPositionRound(styles.build);
        this.addChild(progressBuildIcon);

        var rewardIcon = new cc.Sprite(bundles.village.frames.progress_reward_png);
        rewardIcon.setPositionRound(styles.reward);
        this.addChild(rewardIcon);

        cleverapps.UI.wrap(this);

        village.on("changeProgress", this.createListener(this.updateProgress.bind(this, village)));

        this.updateSize();
    },

    updateSize: function () {
        var styles = cleverapps.styles.VillageProgressView;
        var padding = cc.padding(styles.padding);
        var scene = cleverapps.scenes.getRunningScene();

        cleverapps.UI.fitToBox(this, {
            width: scene.width - padding.left - padding.right,
            height: this.height
        });
    },

    updateProgress: function (village, cb) {
        this.progressBar.setPercentage(village.progress, {
            animated: true,
            callback: cb || function () { }
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_PROGRESS_BAR_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.VillageProgressView = {
    width: 680,
    mobileWidth: 560,
    padding: {
        x: 20
    },

    build: {
        x: { align: "left", dx: -80 },
        y: { align: "center", dy: 10 }
    },

    reward: {
        x: { align: "right", dx: 80 },
        y: { align: "center", dy: 6 }
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("ProgressView");