/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var CrossView = cc.Node.extend({
    ctor: function (cross, id) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setVisible(cross.village.isStateRaid());
        this.cross = cross;
        this.id = id;

        var styles = cleverapps.styles.CrossView;

        var icon = this.icon = new cc.Sprite(bundles.village.frames.cross_png);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);
        icon.setVisible(!cross.raided);

        this.setContentSize2(icon.getContentSize());
        this.setPositionRound(this.cross.position);

        var pit = this.pit = new cc.Sprite(bundles.village.frames.pit_png);
        pit.setPositionRound(styles.pit);
        this.addChild(pit);
        pit.setVisible(cross.raided);

        cleverapps.UI.onClick(this, this.createListener(cross.raid.bind(cross)), { interactiveScale: false });

        cross.on("raided", this.createListener(this.animateDig.bind(this)));

        if (cleverapps.config.debugMode) {
            this.setVisible(cross.village.isStateRaid() || !!cleverapps.flags.villageCrossEnabled);
            cleverapps.flags.on("change:villageCrossEnabled", function () {
                this.setVisible(!!cleverapps.flags.villageCrossEnabled);
            }.bind(this));
        }

        if (cross.village.isStateRaid() && !cross.raided) {
            this.show();
        }
        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.CrossView;
        this.setScale(styles.scale[cleverapps.resolution.mode]);
       },

    show: function () {
        this.setScale(0.1);
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.5 + 0.1 * this.cross.id),
            new cc.Show(),
            cleverapps.UI.animateScale({
                duration: 0.1,
                scale: 1,
                overScaling: 1.2
            })
        ));
    },

    animateDig: function (gold, cb) {
        var styles = cleverapps.styles.CrossView;
        this.icon.runAction(
            new cc.Sequence(
                new cc.CallFunc(cb || function () { }),
                new cc.ScaleTo(0.3, 0.6).easing(cc.easeCubicActionIn()),
                new cc.Hide(),
                new cc.CallFunc(function () {
                    this.pit.setScale(0.6);
                    this.pit.setVisible(true);
                    this.pit.runAction(
                        new cc.ScaleTo(0.3, 1).easing(cc.easeCubicActionOut())
                    )
                }.bind(this))
            )
        );
    }
});

cleverapps.styles.CrossView = {
    scale: [1.6, 1, 1],
    pit: {
        x: { align: "center" },
        y: { align: "center" }
    },
    icon: {
       x: { align: "center" },
        y: { align: "center" }
    },

};