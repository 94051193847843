/**
 * Created by Mikhail Menshenin on 16.07.2024
 */

DailyTaskView.prototype.createIcon = cleverapps.extendFunc(DailyTaskView.prototype.createIcon, function () {
    var icon = this._super();

    if (cleverapps.config.debugMode) {
        cleverapps.UI.onClick(icon, function () {
            cleverapps.eventBus.trigger("taskEvent", this.task.type, 1, this.task.data);
        }.bind(this), {
            interactiveScale: false
        });
    }

    return icon;
});