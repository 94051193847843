/**
 * Created by Mikhail Menshenin on 29.07.2024
 */

GitManager.EDITORS = GitManager.EDITORS || {};

GitManager.EDITORS.villages = {
    apply: function (data) {
        var village = data.village;
        var hut = data.hut;
        var cross = data.cross;
        var position = data.position;
        var mode = data.mode;

        var info = this.prepare();
        var file = info.file;
        var content = info.content;

        var villageData = content.find(function (level) {
            return level.name === village;
        }) || {};
        var hutsList = villageData.huts || [];
        var crossList = villageData.crosses || [];
        var data = hut ? hutsList.find(function (data) {
            return data.name === hut;
        }) : (cross !== undefined ? crossList[cross] : {});
        data.position = data.position || [{}, {}, {}];
        data.position[mode] = position;

        var changes = {};
        changes[file] = {
            content: JSON.parse(JSON.stringify(content))
        };

        return changes;
    },

    load: function (file, data) {
        var info = this.prepare();
        var content = info.content;

        if (content && data.content) {
            content.length = 0;
            data.content.forEach(function (village) {
                content.push(village);
            });
        }

        cleverapps.git.urls[file] = data.content;
    },

    cache: function (data) {
        var info = this.prepare();
        var file = info.file;
        var content = data.content || info.content;

        var cached = {};
        cached[file] = {
            content: content ? JSON.parse(JSON.stringify(content)) : undefined
        };

        return cached;
    },

    restore: function (file, data) {
        this.load(file, data);
        delete cleverapps.git.urls[file];
    },

    prepare: function () {
        var bundleName = Episode.BundleId("villages");
        var bundle = bundles[bundleName];
        var levels = bundle.jsons.levels;
        var file = levels.originalPath();
        var content = cc.loader.getRes(levels);

        return {
            bundleName: bundleName,
            file: file,
            content: content
        };
    }
};