/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var Cross = function (village, data, id) {
    cleverapps.EventEmitter.call(this);
    this.village = village;
    this.id = id;
    this.position = id;
    this.raided = false;

    this.init(data);
};

Cross.prototype = Object.create(cleverapps.EventEmitter.prototype);
Cross.prototype.constructor = Cross;

Cross.prototype.init = function (data) {
    Object.assign(this, data);

    if (Array.isArray(this.position)) {
        this.position = this.position.map(function (pos) {
            var x = pos.x || { align: "center" };
            var y = pos.y || { align: "center" };

            return {
                x: x,
                y: y
            };
        });
    }
};

Cross.prototype.raid = function () {
    if (!this.raided && this.village.isStateRaid()) {
        this.raided = true;

        var gold = this.village.getGoldByRaid();
        this.trigger("raided", gold, this.village.raid.bind(this.village, gold));
    }
};

Cross.prototype.getInfo = function () {
    var info = {};
    info.raided = this.raided;

    return info;
};