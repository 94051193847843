/**
 * Created by olga on 02.08.2024
 */

BeforeWinAction = cleverapps.extendFunc(BeforeWinAction, function () {
    if (aisensia.levelMastery) {
        aisensia.levelMastery.gamePlayed(this.outcome, this);
    }

    if (aisensia.medalCollection) {
        aisensia.medalCollection.gamePlayed(this.outcome, this);
    }

    if (levels.user.getHumanReadableNumber() >= 30) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SCORE_LEVEL, { level: level });
    }

    this._super();
});
