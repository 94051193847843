/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    items: {
        dy: 2
    },
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    scale: [0.6, 0.8, 1],
    vertical: {
        padding: {
            x: 16,
            y: 0
        },
        menuBar: {
            x: { align: "left", dx: -18 },
            y: { align: "center" }
        }
    },
    horizontal: {
        padding: {
            x: 80,
            y: 10
        },
        menuBar: {
            x: { align: "left", dx: -30 },
            y: { align: "center" }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.Avatar, {
    x: 5,
    top: 6,
    bottom: 6
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 270,
            horizontal: 20
        },
        {
            top: 246,
            horizontal: 62
        },
        {
            top: 246,
            horizontal: 62
        }
    ],
    margin: 32
});
