/**
 * Created by Mikhail Menshenin on 26.07.2024
 */

var controlButtons = ControlButtonsView.controlButtons;

var ControlButtonsView = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.ControlButtonsView;

        this.buttons = ControlButtonsView.controlButtons.filter(function (item) {
            return item.logic.isAvailableInEpisode();
        }).map(function (item) {
            var ViewClass = item.view || ControlButton;
            return new ViewClass(item);
        });

        this._super(this.buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    }
});

ControlButtonsView.controlButtons = controlButtons;