/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var VillageAttackScene = VillageActionScene.extend({
    transitionType: Transition.TYPE_ATTACK,

    ctor: function (options) {
        this._super();

        this.village.prepareToAttack(options);
    },

    getVillage: function () {
        return aisensia.villageManager.getAttackVillage();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_VILLAGE_ATTACK);
    },

    addUI: function () {
        var styles = cleverapps.styles.VillageAttackScene;

        var hammer = new cc.Sprite(bundles.village.frames.hammer);
        hammer.setPositionRound(styles.hammer);
        this.addChild(hammer);

        this.addControlPanel(styles);
    },

    addControlPanel: function (styles) {
        var controlPanel = new AttackControlPanelView(this.village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        this.addChild(controlPanel);
        controlPanel.show();
    },

    handleFinishEvent: function (f) {
        this.village.on("attacked", function () {
            f();
        });
    }
});

cleverapps.styles.VillageAttackScene = {
    hammer: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    control: {
        width: 570,
        height: 260,
        x: { align: "center", dx: 3 },
        y: { align: "top", dy: 25 }
    }
};

cleverapps.Environment.SCENE_VILLAGE_ATTACK = "villageAttack";