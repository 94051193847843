/**
 * Created by Mikhail Menshenin on 08.08.2024
 */

var StarterPackIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.starterpack_json,
        targets: ["StarterPackIcon"],
        control: "StarterPackIcon",
        hiddenByDefault: Object.values(cleverapps.Environment).filter(function (scene) {
            return ![cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_SLOT_MACHINE].includes(scene);
        })
    });

    this.resetState();
};

StarterPackIcon.prototype = Object.create(SideBarIcon.prototype);
StarterPackIcon.prototype.constructor = StarterPackIcon;

StarterPackIcon.prototype.resetState = function () {
    this.available = true;
};