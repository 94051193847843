/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var Hut = function (village, data, id) {
    cleverapps.EventEmitter.call(this);
    this.village = village;
    this.id = id;
    this.position = id;
    this.level = 0;
    this.damage = 0;
    this.upgrades = [];
    this.repairs = [];
    this.attacked = false;
    this.upgradeable = false;

    this.init(data);

    if (this.village.isStateBuild()) {
        var price = this.getPrice();
        cleverapps.user.on({
            hard: "changeHard",
            soft: "changeSoft"
        }[price.type], this.checkUpgradeable.bind(this));

        this.checkUpgradeable();
    }
};

Hut.prototype = Object.create(cleverapps.EventEmitter.prototype);
Hut.prototype.constructor = Hut;

Hut.DEFENSE_PROBABILITY = 0.5;

Hut.prototype.init = function (data) {
    Object.assign(this, data);

    if (Array.isArray(this.position)) {
        this.position = this.position.map(function (pos) {
            var x = pos.x || { align: "center" };
            var y = pos.y || { align: "center" };

            return {
                x: x,
                y: y
            };
        });
    }

    this.upgrades = Hut.UPGRADE_COST_COEFFS[this.id].map(function (cost) {
        return {
            "soft": Math.round((cost / 100) * this.village.cost)
        }
    }.bind(this));
};

Hut.prototype.getFrameId = function (level) {
    level = level || this.getCurrentLevel();
    return this.name + "_" + level;
};

Hut.prototype.getCurrentLevel = function () {
    var level = this.level - this.damage;

    if (level < 0) {
        level = 0;
    }

    if (level > Hut.MAX_LEVEL) {
        level = Hut.MAX_LEVEL;
    }

    return level;
};

Hut.prototype.getMaxLevel = function () {
    return this.upgrades.length;
};

Hut.prototype.upgrade = function () {
    var eventName = cleverapps.EVENTS.SPENT.VILLAGE_HUT;
    var price = this.getPrice();
    var bought = ({
        soft: cleverapps.user.spendSoft.bind(cleverapps.user, eventName),
        hard: cleverapps.user.spendHard.bind(cleverapps.user, eventName)
    }[price.type] || function () { })(price.amount) || false;

    if (bought) {
        this.setLevel(this.level + 1);
    }
};

Hut.prototype.repair = function () {
    var eventName = cleverapps.EVENTS.SPENT.VILLAGE_HUT;
    var price = this.getPrice();
    var bought = ({
        soft: cleverapps.user.spendSoft.bind(cleverapps.user, eventName),
        hard: cleverapps.user.spendHard.bind(cleverapps.user, eventName)
    }[price.type] || function () { })(price.amount) || false;

    if (bought) {
        this.setDamage(this.damage + 1);
    }
};

Hut.prototype.attack = function () {
    if (this.damage >= this.level) {
        return;
    }

    var shields = this.village.shields;
    var p = Math.random();

    if (shields && p <= Hut.DEFENSE_PROBABILITY) {
        this.village.setShields(shields - 1);
    } else {
        this.setDamage(this.damage + 1);
    }

    this.village.attack();
};

Hut.prototype.canAttack = function () {
    return this.village.isStateAttack() && !this.attacked && (this.getCurrentLevel() > 0);
};

Hut.prototype.setLevel = function (level) {
    this.level = level;

    if (this.level > Hut.MAX_LEVEL) {
        this.level = Hut.MAX_LEVEL;
    }

    if (this.level < 0) {
        this.level = 0;
    }

    this.village.updateProgress();

    this.trigger("changeLevel");
};

Hut.prototype.setDamage = function (damage) {
    this.damage = damage;

    if (this.damage > this.level) {
        this.damage = this.level;
    }

    if (this.damage < 0) {
        this.damage = 0;
    }

    this.village.save();

    this.trigger("changeDamage");
};

Hut.prototype.isComplete = function () {
    return this.level === this.upgrades.length && !this.damage;
};

Hut.prototype.isVisible = function () {
    return Boolean(this.level);
};

Hut.prototype.getPrice = function () {
    var priceData = (this.damage ? this.repairs[this.getCurrentLevel()] : this.upgrades[this.level]) || { hard: 10000000 };
    var type = Object.keys(priceData)[0];
    var amount = priceData[type];
    return {
        type: type,
        amount: amount
    };
};

Hut.prototype.getInfo = function () {
    var info = this.level ? { level: this.level } : undefined;

    if (info && this.damage) {
        info.damage = this.damage;
    }

    return info;
};

Hut.prototype.load = function (data) {
    if (!data) {
        return;
    }

    this.level = data.level || this.level;
    this.damage = data.damage || this.damage;

    this.checkUpgradeable();
};

Hut.prototype.onClick = function () {
    if (this.village.isStateBuild()) {
        this.village.openUpgradeWindow();
    }

    if (this.canAttack()) {
        this.attack();
    }
};

Hut.prototype.checkUpgradeable = function () {
    var price = this.getPrice();
    var upgradeable = this.isComplete() ? false : {
        hard: cleverapps.user.canTakeHard.bind(cleverapps.user),
        soft: cleverapps.user.canTakeSoft.bind(cleverapps.user)
    }[price.type](price.amount);

    if (this.upgradeable !== upgradeable) {
        this.upgradeable = upgradeable;
        this.trigger("changeUpgradeable");
    }
};

Hut.MAX_LEVEL = 4;

Hut.UPGRADE_COST_COEFFS = [
    [1.5, 1.8, 3.0, 5.0],
    [2.0, 2.5, 4.2, 6.9],
    [2.5, 3.0, 5.2, 8.5],
    [3.0, 3.6, 6.2, 10.2],
    [4.0, 5.0, 8.3, 13.6]
];
