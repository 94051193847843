/**
 * Created by Mikhail Menshenin on 16.07.2024
 */

var VillageManager = function () {
    this.init(bundles.villages.jsons.config.data);
};

VillageManager.prototype.init = function (configs) {
    this.configs = configs.reduce(function (configs, config) {
        var levelNo = config.levelNo;
        configs[levelNo] = config;

        return configs;
    }.bind(this), {});

    this.villages = {};
};

VillageManager.prototype.getVillage = function (player) {
    player = player || this.getCurrentPlayer();
    var playerId = player.id;
    var isUser = this.isUser(player);
    var village = this.villages[playerId];

    if (!village) {
        var villageLevel = player.villageLevel !== undefined ? player.villageLevel : player.simpleCurrent;
        player.villageLevel = villageLevel || 0;
        var config = this.configs[player.villageLevel] || {};
        var data = isUser ? this.load() : this.generate(config);
        village = new Village(config, player);
        village.load(aisensia.savedVillage || data);

        this.villages[playerId] = village;
    }

    return village;
};

VillageManager.prototype.getAttackVillage = function () {
    var player = aisensia.villagePlayers.getAttackPlayer();
    return this.getVillage(player);
};

VillageManager.prototype.getRaidVillage = function () {
    var player = aisensia.villagePlayers.getRaidPlayer();
    return this.getVillage(player);
};

VillageManager.prototype.load = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.VILLAGE);
};

VillageManager.prototype.save = function (village) {
    if (this.isUser(village.player)) {
        cleverapps.dataLoader.save(DataLoader.TYPES.VILLAGE, village.getInfo());
    }
};

VillageManager.prototype.isUser = function (player) {
    var userId = connector.platform.getUserID();
    return userId === player.id;
};

VillageManager.prototype.generate = function (config) {
    var data = {};

    data.shields = cleverapps.Random.random(0, Village.MAX_SHIELDS);
    data.gold = cleverapps.Random.random(500, 999) * 1000;

    data.huts = config.huts.reduce(function (huts, hut) {
        var level = cleverapps.Random.random(0, Hut.MAX_LEVEL);

        if (level) {
            var data = { level: level };
            var damage = cleverapps.Random.random(0, level - 1);
            if (damage) {
                data.damage = damage;
            }
            huts[hut.name] = data;
        }

        return huts;
    }, {});

    return data;
}

VillageManager.prototype.getCurrentPlayer = function () {
    return {
        id: connector.platform.getUserID(),
        villageLevel: cleverapps.simple.getCurrent()
    };
}

VillageManager.prototype.canMoveNext = function () {
    var village = this.getVillage();

    return village.level < this.getMaxLevel();
};

VillageManager.prototype.moveNext = function () {
    if (this.canMoveNext()) {
        cleverapps.meta.display({
            focus: "NextVillageScene",
            control: [],
            actions: [
                function (f) {
                    this.clearVillageData();
                    this.simpleChangeCurrent();
                    f();
                }.bind(this),
                function (f) {
                    var scene = new VillageScene();
                    cleverapps.scenes.replaceScene(scene, f);
                }
            ]
        });
    }
}

VillageManager.prototype.clearVillageData = function () {
    var player = this.getCurrentPlayer();
    var playerId = player.id;

    if (this.villages[playerId]) {
        delete this.villages[playerId];
    }

    cleverapps.dataLoader.remove(DataLoader.TYPES.VILLAGE);
}

VillageManager.prototype.simpleChangeCurrent = function () {
    cleverapps.simple.current++;
    cleverapps.simple.save();
}

VillageManager.prototype.getMaxLevel = function () {
    return Math.max.apply(Math, Object.keys(this.configs));
};

DataLoader.TYPES.VILLAGE = "_village";
