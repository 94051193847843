/**
 * Created by Mikhail Menshenin on 28.08.2024
 */

var VillageTitleView = cc.Node.extend({
    avoidNode: "villageTitle",

    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.VillageTitleView;

        var bg = cleverapps.UI.createScale9Sprite(bundles.village.frames.title_bg_png);
        bg.setPositionRound(styles.bg);
        this.addChild(bg);

        var text = cleverapps.UI.generateOnlyText("Village.Title." + village.name, cleverapps.styles.FONTS.VILLAGE_TITLE_TEXT);
        text.setPositionRound(styles.text);
        this.addChild(text);

        var padding = cc.padding(styles.padding);
        var textWidthWithPadding = text.width + padding.left + padding.right;
        var width = Math.max(textWidthWithPadding, bg.width);
        bg.setContentSize(width, bg.height);
        this.setContentSize(bg.getContentSize());
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_TITLE_TEXT: {
        size: 28,
        color: new cc.Color(175, 74, 25),
        stroke: undefined,
        shadow: {
            color: new cc.Color(136, 56, 0, 255),
            direction: cc.size(0, 3),
            blur: 2
        }
    }
});

cleverapps.styles.VillageTitleView = {
    padding: {
        x: 66
    },

    bg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    text: {
        x: { align: "center" },
        y: { align: "bottom", dy: 4 }
    }
};