/**
 * Created by Slava on 02.07.2024
 */

DailyTasksTab.prototype.ctor = cleverapps.extendFunc(DailyTasksTab.prototype.ctor, function (window) {
    this._super(window);
    var items = [];

    items.push(new DailyTasksCompleteAllView());
    items.push(this.createScroll());

    this.setItems(items);
});