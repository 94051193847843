/**
 * Created by Mikhail Menshenin on 04.09.2024
 */

ResourceProcessor.calcAlwaysNeed = cleverapps.extendFunc(ResourceProcessor.calcAlwaysNeed, function () {
    var bundles = this._super();

    bundles.push("villages");

    return bundles;
});