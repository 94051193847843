/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

var MapIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.map_json,
        targets: ["MapIcon"],
        control: "MapIcon"
    });

    this.resetState();
};

MapIcon.prototype = Object.create(SideBarIcon.prototype);
MapIcon.prototype.constructor = MapIcon;

MapIcon.prototype.resetState = function () {
    this.available = cleverapps.environment.hasScene(cleverapps.Environment.SCENE_SLOT_MACHINE);
};

MapIcon.prototype.onPressed = function () {
    cleverapps.scenes.getRunningScene().closeAction();
};