/**
 * Created by Mikhail Menshenin on 27.08.2024
 */

RewardTypes.shields = {
    controls: "shields",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return bundles.slotmachine.frames.shield_png;
    },

    handler: function (value, options) {
        var village = aisensia.villageManager.getVillage();
        var shields = village.shields + value;

        return function () {
            if (shields > Village.MAX_SHIELDS) {
                aisensia.slotMachine.addEnergy(1, false, true);
            } else {
                village.setShields(shields);
            }
        };
    }
};