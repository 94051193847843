/**
 * Created by Mikhail Menshenin on 30.07.2024
 */

cleverapps.NodeDebugCommands.move.action = cleverapps.extendFunc(cleverapps.NodeDebugCommands.move.action, function (arrowKey) {
    this._super(arrowKey);

    if (this instanceof HutView && cleverapps.flags.villageHutMove) {
        var village = this.hut.village.name;
        var hut = this.hut.name;
        var position = this.getPosition();
        var modes = cleverapps.resolution.mode ? [1, 2] : [0];

        modes.forEach(function (mode) {
            cleverapps.git.edit("villages", {
                village: village,
                hut: hut,
                position: position,
                mode: mode
            });
        });
    }

    if (this instanceof CrossView && cleverapps.flags.villageHutMove) {
        var village = this.cross.village.name;
        var cross = this.cross.id;
        var position = this.getPosition();
        var modes = cleverapps.resolution.mode ? [1, 2] : [0];

        modes.forEach(function (mode) {
            cleverapps.git.edit("villages", {
                village: village,
                cross: cross,
                position: position,
                mode: mode
            });
        });
    }
});

cc.Base.prototype.createEditButtons = function (options) {
    delete this.debugEditUnitAnchorsMode;

    if (options.noResLinks) {
        return;
    }

    var styles = cleverapps.styles.NodeDebug.button;

    var createButton = function (options) {
        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            content: options.content,
            onClicked: options.onClicked,
            hint: options.hint,
            width: styles.width,
            height: styles.height
        });
    };

    var link = this instanceof cc.LabelTTF ? this.showLinkToLocalization() : this.showUnpackedRes();
    if (link) {
        var editLink = createButton({
            content: new cc.Sprite(bundles.dev_buttons.frames.icon_edit),
            hint: "Open link to git",
            onClicked: function () {
                if (this.debugTextCode) {
                    cleverapps.copyToClipboard(this.debugTextCode);
                }
                setTimeout(window.open.bind(window, link, "_blank"), 0);
            }.bind(this)
        });
    }

    if (!cleverapps.git.checkAvailableErrors() && typeof UnitView !== "undefined" && this instanceof UnitView) {
        var editAnchorsButton = createButton({
            content: cleverapps.UI.generateOnlyText("A", cleverapps.UI.Button.WorkFont()),
            hint: "Toggle edit anchors mode",
            onClicked: function () {
                if (cleverapps.flags.keyboardAnchorsMode) {
                    cleverapps.notification.create("Disable anchors mode in debug tool (or press 'a').");
                    return;
                }

                this.debugEditUnitAnchorsMode = !this.debugEditUnitAnchorsMode;

                updateAnchorsButton();

                cleverapps.notification.create("Edit anchors mode " + (this.debugEditUnitAnchorsMode ? "activated" : "switched off"));
            }.bind(this)
        });

        var updateAnchorsButton = function () {
            editAnchorsButton.setType(cleverapps.flags.keyboardAnchorsMode || this.debugEditUnitAnchorsMode ? cleverapps.styles.UI.Button.Images.small_button_blue : cleverapps.styles.UI.Button.Images.small_button_green);
        }.bind(this);

        cleverapps.flags.on("change:keyboardAnchorsMode", updateAnchorsButton, editAnchorsButton);

        updateAnchorsButton();
    }

    if (this.unit && Families[this.unit.code].units[0].mission) {
        var demoLink = createButton({
            content: new cc.Sprite(bundles.dev_buttons.frames.icon_stages),
            hint: "Open unit demo",
            onClicked: function () {
                var url = cleverapps.RestClient.standsWeb("/?unitsDemo=" + cleverapps.travelBook.currentPage.id + "&code=" + this.unit.code);
                setTimeout(window.open.bind(window, url, "_blank"), 0);
            }.bind(this)
        });
    }

    if (this instanceof HutView) {
        var editPositionButton = createButton({
            content: cleverapps.UI.generateOnlyText("P", cleverapps.UI.Button.WorkFont()),
            hint: "Toggle edit position mode",
            onClicked: function () {
                cleverapps.flags.toggle("villageHutMove");

                updateEditPositionButton();
            }.bind(this)
        });

        var updateEditPositionButton = function () {
            editPositionButton.setType(cleverapps.flags.villageHutMove ? cleverapps.styles.UI.Button.Images.small_button_blue : cleverapps.styles.UI.Button.Images.small_button_green);
        }.bind(this);

        cleverapps.flags.on("change:villageHutMove", updateEditPositionButton, editPositionButton);

        updateEditPositionButton();
    }

    var buttons = [editLink, editAnchorsButton, demoLink, editPositionButton].filter(Boolean);
    if (!buttons.length) {
        return;
    }

    return new cleverapps.Layout(buttons, {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.margin
    });
};
