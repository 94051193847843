/**
 * Created by Slava on 28.06.2024
 */

cleverapps.override(cleverapps.EVENTS, {
    SCORE_LEVEL: "score_level",

    SPENT: {
        VILLAGE_HUT: "village_hut"
    },

    EARN: {
        LEVELMASTERY: "levelmastery"
    }
});
