/**
 * Created by Mikhail Menshenin on 19.08.2024
 */

var ShieldsView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var animation = this.animation = new cleverapps.Spine(bundles.village_shields.jsons.shield_animation);
        animation.setPosition(this.width / 2, this.height / 2);
        animation.setAnimation(0, "animation_00", false);
        this.addChild(animation);

        this.updateShields(village);
        village.on("changeShields", this.createListener(this.updateShields.bind(this, village)));
    },

    updateShields: function (village) {
        var shields = village.shields;
        var frame = "animation_0" + shields;
        this.animation.setAnimation(0, frame, false);
    }
});