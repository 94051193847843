/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var CoinKingdom = function (level, options) {
    GameBase.call(this, level, options);

    this.counter = aisensia.slotMachine.counter;
};

var Game = CoinKingdom;

CoinKingdom.prototype = Object.create(GameBase.prototype);
CoinKingdom.constructor = CoinKingdom;
